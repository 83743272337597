import React, { useEffect, useState } from 'react'
import JustGameLogo from '../components/assets/images/just-game-logo.png'
import GooglePlay from '../components/assets/images/google-play.png'
import AppStore from '../components/assets/images/app-store.png'
import Thinktrek from '../components/assets/images/thinktrek.png'
import { Link, useNavigate } from 'react-router-dom'
import Footer from './Footer'
import GameData from "../components/Games.json";
import Header from './Header'

const Terms = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const navigate = useNavigate();
  const [isToggle, setIsToggle]= useState(false)
  const scrollToParent = () => {
    // Navigate to the parent route
    navigate('/');

    // Use a small delay to allow the navigation to happen before scrolling
    setTimeout(() => {
        // Find the parent section and scroll into view
        const parentSection = document.querySelector('#about');


        if (parentSection) {
            parentSection.scrollIntoView({ behavior: 'smooth' });
        }
    }, 100);
};
  return (
    <div>
     <Header />
      <section className='terms_sec'>
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">TERMS & CONDITIONS</h2>
              
              <p>These Terms and Conditions of Use ("Terms") constitute a legally binding agreement between Thinktrek Entertainment Private Limited (hereinafter referred to as the "Company," "JustGame," "We," "Us," or "Our") and any individual or entity ("You," "Your," or "User") who accesses or uses:</p>
              <ul>
                <li>Our website, www.justgame.in (the "Website"), and the JustGame mobile application (the "App"), including any sub-domains or related top-level domains (collectively referred to as the "Platform"); and/or</li>
                <li>Any data, information, text, graphics, video, sound, pictures, or other materials displayed, transmitted, or made available through the Platform (collectively referred to as the "Content") for the purpose of playing or participating in various contests and games offered on the Platform (the "Services" or "Games").
                </li>
              </ul>

              <p>
                Thinktrek Entertainment Private Limited operates an online platform that hosts skill-based games, which may be played casually or for real money or monies worth, as well as related content and services (the "Services"). The JustGame mobile application ("Platform") is included within the scope of the Services offered to end users (the "Users," "You," or "Your").
              </p>
              <p>
                These Terms of Use ("Terms") are legally binding and must be read in conjunction with the Privacy Policy available at [https://www.justgame.in/privacy]. Together, these documents form a binding agreement between You and the Company.
              </p>

              <p>
              By accessing, downloading, or using the Platform or Services, You acknowledge and agree to be bound by these Terms. Please review these Terms carefully before using the Platform or participating in any contests or games. It is recommended that You periodically check these Terms for any updates or changes. By continuing to use the Platform or the Services after any modifications, You provide Your effective and valid consent to the revised Terms.
              </p>
              <p>
              Users acknowledge and agree that they may receive periodic communications regarding their obligation to comply with these Terms.
              </p>
              <p>
              This document constitutes an electronic record under the provisions of the Information Technology Act, 2000, and the rules thereunder, as well as various other applicable statutes. This electronic record is generated by a computer system and does not require any physical or digital signatures.
              </p>

              <h4>Acknowledgment and Agreement</h4>
              <p>
              By accessing, using, or otherwise engaging with the JustGame Platform and Services, you acknowledge and agree to be bound by these Terms and our Privacy Policy, which is incorporated herein by reference. You further acknowledge that your use of the Platform and Services is conditional upon your acceptance of these Terms. You confirm that you have read and fully understood these Terms and the Privacy Policy as they apply to your use of the Platform and participation in any Games or contests available on it. You also agree to adhere to any specific rules or regulations applicable to each Game.
              </p>

              <h4>Amendments to Terms and Policies</h4>
              <p>
              We reserve the right to amend, update, modify, or supplement these Terms, the Privacy Policy, and any rules or regulations associated with the Platform at any time. Any changes will be effective upon publication of the updated Terms on the Platform. We may also, at our sole discretion, notify you of such changes by sending an email to your registered email address or posting a notification within your user account.

              </p>
              <p>
              Upon receiving such notice, you have the option to indicate your non-acceptance of the revised Terms, Privacy Policy, or any rules and regulations within the time frame specified. Failure to do so will be deemed as acceptance of the modified terms. If you do not accept the changes, we reserve the right to restrict your access to the Platform and games. If you do not accept the revised Privacy Policy, access to the Platform and Services may be restricted to the extent required to enforce the modified policy.

              </p>

              <h4>Additional Terms for Games and Contests</h4>
              <p>
              Participation in any Games or contests on the Platform or access to certain Content may be subject to additional terms and conditions, which will be notified to you from time to time on the Website or through other means. You are responsible for ensuring that you have read, understood, and agreed to any such rules and regulations applicable to the Games, contests, or tournaments available through the Platform.
              </p>

              <h4>Platform Management and Content Control</h4>
              <p>The Company, at its sole and absolute discretion, reserves the right to:</p>
              <ul>
                <li>Move, modify, or remove any Content that has been posted by a User on the Platform;</li>
                <li>Establish, modify, or limit general practices, usage limits, and thresholds concerning the use of the Platform, as deemed appropriate by the Company from time to time. Any such changes will be incorporated into the updated Terms and notified to Users as necessary;</li>
                <li>Assign its rights and liabilities under these Terms to any entity, with notification of such assignment to be provided to Users via their registered email addresses.</li>
              </ul>

              <h4>Use of Information and Disclaimer</h4>
              <p>
              Your use of information provided on our Platform, including but not limited to results, fixture lists, statistics, data, and news, is at your own risk. Other than as expressly set forth in these Terms and to the extent permitted by law, we make no warranties regarding the accuracy, reliability, or completeness of the information available on our Platform or in our publications. This includes, but is not limited to, Content generated on the Platform by or on behalf of us, as well as third-party content. The Company, along with its related entities, directors, officers, and agents, disclaims all liability and responsibility for any loss or damage, whether direct or indirect, that may result from your reliance on any information contained in or omitted from the Platform or our publications.
              </p>

              <h4>Consent to Data Transfer</h4>
              <p>
              By using the Platform and Services, you hereby unconditionally and irrevocably consent to the transfer of your personal data, including sensitive data, to Us and to third parties with whom we contract in order to provide you with the Services. This transfer is governed by applicable data protection laws and is subject to the terms of our Privacy Policy.
              </p>

              <h4>
              Services
              </h4>
              <ul>
                <li>You hereby represent and warrant that you have attained the age of majority in accordance with applicable laws and are at least 18 years old to access and use the pay-to-play Services and the Platform. You agree that you will not, under any circumstances, access or use the Services or Platform if you are under the age of 18.
                </li>
                <li>The games available on the Platform that can be played for real money are open only to residents of India, <strong>excluding residents of Assam, Odisha, Nagaland, Sikkim, Meghalaya, Andhra Pradesh, and Telangana (collectively referred to as "Prohibited States")</strong>. For the purposes of these Terms, "Prohibited States" shall mean states where playing games of skill for real money is prohibited, either by legislation or judicial precedent. This list of Prohibited States is subject to change, and you are advised to regularly review the Terms to stay informed about any updates. Users residing in Prohibited States are permitted to access the free-to-play versions of all games, where such versions are made available on JustGame.
                </li>
                <li>If you are residing in or accessing the Platform from any jurisdiction where participation in contests for stakes is prohibited or restricted by law, you are prohibited from registering for or participating in such contests. You are solely responsible for ensuring that your use of the Platform and Services complies with the laws applicable to you.</li>
                <li>If you participate in any contest by misrepresentation or fraudulent means, we reserve the right, at our sole discretion, to disqualify you at any stage of the process, withhold any prizes or winnings, and pursue legal action against you. If you are found or suspected of defrauding the Platform in any manner, you will be barred from further participation in contests, and legal action may be taken against you.
                </li>
                <li>No individual, whether employed by JustGame or otherwise involved in the development, maintenance, or operation of the Services, may participate in any contest on the Platform if such participation may affect or unfairly influence their performance in the contest, except with the prior written permission of JustGame and subject to any terms and conditions imposed by JustGame.</li>
                <li>JustGame reserves the right to withdraw, discontinue, or terminate any contest at any time without prior notice and without any liability to you.</li>
                <li>In any contest played for real money on the Platform, in the event of a draw or tie, the final decision shall rest with JustGame. No refunds shall be issued under such circumstances, and you agree to abide by JustGame's decision.</li>
                <li>Subject to the User's full compliance with these Terms, <strong>Thinktrek Entertainment Private Limited</strong> (hereinafter referred to as the "Company" or "JustGame") grants the User a personal, royalty-free, non-assignable, non-sublicensable, non-transferable, non-exclusive, and revocable limited license to access and use the Platform. This license is granted solely for the User's personal, private use to participate in Games of skill hosted on the Platform, as per these Terms. The license does not extend to any commercial use and is restricted to use within India, valid only as long as the User complies with these Terms.</li>
                <li>The granted license does not confer any ownership rights, and the Company reserves all rights not expressly granted herein. Users are prohibited from sublicensing, assigning, or otherwise transferring their rights under this license.</li>
                <li>The Company may, from time to time, modify, alter, or discontinue any aspect of the Services, Platform, or related information, including but not limited to any features, functionality, or content, at its sole discretion and without prior notice or communication. The Company reserves the right to update or amend these Terms or the Platform without prior notification. Continued use of the Platform after such modifications shall be deemed acceptance of the modified Terms.</li>
                <li>The Services may include advertisements, which may be targeted to the content, information, or usage patterns on the Platform, including queries made through the Services or other data related to User activity. The nature, type, and extent of advertising on the Platform are subject to change without prior notice. The Company does not guarantee any specific advertising content, and the inclusion of advertisements does not imply any endorsement by the Company.</li>
                <li>For Games included within the Services, the specific rules, scoring systems, controls, and guidelines applicable to each Game will be detailed within the respective Game itself. Users are required to carefully review, understand, and comply with all such rules, scoring, controls, and guidelines before participating in any Game. The Company disclaims any liability arising from non-compliance with these game-specific rules and guidelines.</li>
                <li>Any charges or costs incurred by Users for accessing the Services, including but not limited to internet connection fees, mobile data charges, or other telecommunication expenses, shall remain the sole responsibility of the Users. The Company shall not be liable for any such charges incurred by Users during the use of the Platform or Services.</li>
                <li>The content made available through the Services is provided on an "AS IS" basis. We do not make any representations, warranties, or guarantees regarding the quality, suitability, accuracy, or completeness of any content available on the Platform. Users acknowledge that they bear sole responsibility for, and assume all risks arising from, their use or reliance on any content made available on the Platform.</li>
                <li>You agree not to: (i) circumvent, remove, deactivate, or interfere with any security-related features of the Services; (ii) use any robot, spider, scraper, or other automated means to access the Services; (iii) decompile, reverse engineer, or disassemble any software or other products or processes accessible through the Services; (iv) threaten, defame, harass, bully, or otherwise engage in offensive conduct towards JustGame, its employees, or its service partners; (v) upload, post, email, or otherwise transmit any material intended to interrupt, destroy, or limit the functionality of the Services. If you engage in any conduct that violates these Terms, or engage in illegal or fraudulent use of the Services, JustGame reserves the right to restrict or terminate your access to the Services and to block, restrict, or forfeit any amounts, prizes, or privileges you may have received. Additionally, JustGame may pursue legal action against you, including seeking refunds where applicable.</li>
              </ul>

              <h4>User Registration</h4>
              <p>By registering on the Platform, you represent and warrant that:</p>
              <ul>
                <li>You are at least 18 years of age or have attained the age of majority as per the applicable laws in your jurisdiction.</li>
                <li>You are an Indian resident, and you are not residing in any of the "Prohibited States" where participation in real-money games is restricted or prohibited, as per our list of states which may change from time to time.</li>
                <li>You have the legal capacity and authority to enter into a binding agreement with JustGame and adhere to these Terms.</li>
              </ul>

              <p>To access the Services on the Platform, you must create an account by completing the registration process. This includes providing accurate and complete information, including but not limited to your name, phone number, and any other details required during registration. You agree that:</p>

              <ul>
                <li>You will use a valid phone number for verification purposes, and an OTP will be sent to authenticate your identity.</li>
                <li>By creating an account, you agree to abide by these Terms and our Privacy Policy, which governs the collection, use, and disclosure of your personal data.</li>
                <li>You agree to keep your account credentials secure and confidential and accept responsibility for all activities carried out under your account.</li>
                <li>JustGame reserves the right to restrict, suspend, or terminate your account if it suspects any fraudulent or unauthorized activities.</li>
              </ul>

              <p>
              For the purpose of accessing certain features, including but not limited to withdrawals or playing real-money games, you may be required to complete a Know Your Customer (KYC) process as mandated by law. 

              </p>
              <p>
              You agree and understand that the information provided during KYC will be used for identity verification purposes only and will be securely processed and stored in accordance with our Privacy Policy. You are responsible for providing accurate and complete information. Failure to do so may result in restrictions on your account access, including the inability to withdraw funds. JustGame reserves the right to deny access to certain features or Services if the KYC process is not completed successfully.

              </p>
              <p>
              A User may be able to register or operate up to two User accounts with JustGame. If JustGame is made aware that a User has registered more than two User accounts with JustGame, or is using multiple mobile numbers to play on the Platform, it may restrict your use of the Services, and block, restrict, or forfeit any amounts, prizes, or privileges that you may have received on the Platform, or that have otherwise been given to you.
              </p>
              <p>
              You are solely responsible for maintaining the confidentiality of your account login credentials, including your password. Any unauthorised access or use of your account must be immediately reported to JustGame. You agree that JustGame will not be held liable for any loss or damage arising from unauthorized use of your account. You will promptly update your account information if there are any changes to ensure that your account details remain accurate and up to date.
              </p>

              <p>
              You are solely responsible for maintaining the confidentiality of your account login credentials, including your password. Any unauthorised access or use of your account must be immediately reported to JustGame. You agree that JustGame will not be held liable for any loss or damage arising from unauthorized use of your account. You will promptly update your account information if there are any changes to ensure that your account details remain accurate and up to date.
              </p>

              <h4>Account Termination and Suspension</h4>
              <p>JustGame reserves the right to suspend, limit, or terminate your account at its sole discretion, without prior notice, in the event that:</p>
              <ul>
                <li>You fail to comply with these Terms.</li>
                <li>There is evidence of fraudulent, unlawful, or improper use of your account or the Platform.
                </li>
                <li>You provide false or misleading information during registration or the KYC process.</li>
              </ul>

              <p>
              You acknowledge and agree that any suspension or termination of your account may lead to the forfeiture of any funds or rewards associated with your account, subject to applicable law.
              </p>
              <p>
              Users are prohibited from creating or operating more than one account on the Platform. Any attempt to create multiple accounts, or engage in deceptive practices such as using multiple identities, will result in the immediate suspension or termination of all related accounts, and forfeiture of any winnings or rewards associated with them.
              </p>
              <p>
              In the event that you are erroneously credited with excess funds for any reason, you agree to forfeit the excess amount. JustGame reserves the right to debit such excess amounts from your account without prior notice.
              </p>
              <p>
              Where JustGame conducts customer identity checks, this does not imply any statutory obligation on the Company to perform such checks nor does it make JustGame liable for any inaccuracies in customer verification unless directly caused by the Company's gross negligence.
              </p>
              <p>
              You are responsible for completing your KYC checks with your respective banks, financial institutions, card associations, and other payment system providers as defined under the Payment and Settlement Systems Act, 2007 ("Payment System Providers"). These are entities with whom JustGame has arrangements to facilitate payment transactions on the Platform, such as sending or receiving funds via the services of such Payment System Providers. JustGame conducts such checks as a preventive measure to reduce risks of financial crimes, including online fraud, money laundering, and the financing of terrorism.
              </p>
              <p>
              By accepting these terms and conditions upon registration, you consent to JustGame disclosing your personal information to third-party agencies to assist in verifying your identity. These third-party agencies may prepare and provide an identity verification report to JustGame and may use your personal information, including but not limited to names, residential addresses, dates of birth, and financial information, for the purpose of preparing such reports. Please refer to our Privacy Policy for more details on how your personal information is handled.
              </p>
              <p>
              You acknowledge that your participation in any games or contests available on the JustGame Platform is entirely voluntary, undertaken at your sole discretion and risk. JustGame shall not be held liable for any consequences arising from your participation in such games, including but not limited to financial losses or disputes.
              </p>
              <p>
              By registering an account on the Platform, you agree to receive communication, including promotional offers and notifications, via SMS, WhatsApp, and email, unless you opt out. You may choose to opt out of receiving promotional messages at any time by updating your preferences through your account settings.
              </p>
              <p>Without limiting the foregoing, Users agree not to use the Services for any of the following:</p>
              <ul>
                <li>To engage in any obscene, offensive, indecent, racial, communal, anti-national, objectionable, defamatory or abusive action or communication and to exercise due caution and discretion in the use of JustGame;</li>
                <li>To harass, stalk, threaten, or otherwise violate any legal rights of other individuals;</li>
                <li>To publish, post, upload, e-mail, distribute, or disseminate (collectively, "Transmit") any inappropriate, profane, defamatory, infringing, obscene, indecent, or unlawful content;</li>
                <li>To advertise, offer or sell any goods or services for any commercial purpose on JustGame without the express written consent of JustGame;</li>
                <li>To falsify or delete any author attributions, legal or other proper notices or proprietary designations or labels of the origin or the source of software or other material;</li>
                <li>To impersonate any person or entity, including, but not limited to, a representative of JustGame, or falsely state or otherwise misrepresent User's affiliation with a person or entity;</li>
                <li>To forge headers or manipulate identifiers or other data in order to disguise the origin of any content transmitted through JustGame or to manipulate User's presence on JustGame(s);</li>
                <li>To publish information that threatens the unity, integrity, defense, security or sovereignty of India, friendly relations with foreign States, or public order, or causes incitement violence or disturb the maintenance of public order or the commission of any cognizable offense or prevents investigation of any offense or is insulting any other nation;</li>
                <li>To publish information that is an invasion of another's privacy, their bodily privacy, insulting or harassing on the basis of gender, libelous, racially or ethnically objectionable, relating or encouraging money laundering or gambling, or otherwise inconsistent with or contrary to the laws in force;</li>
                <li>To engage in any illegal activities. You agree to use our bulletin board services, chat areas, news groups, forums, communities and/or message or communication facilities (collectively, the "Forums") only to send and receive messages and material that are proper and related to the purpose of that particular Forum.</li>
              </ul>

              <p>Users must please review and familiarize themselves with JustGame’s Responsible Gaming Policy, available at https://justgame.in/responsible and undertake to access the Platform and our Services in compliance with this Policy.</p>
              <p>
              If you wish to delete your account, you may do so through the profile menu on the JustGame Platform, or as per our privacy policy, available at https://www.justgame.in/privacy. Once your account deletion request is authenticated, we may initially deactivate your account for 90 days or may immediately close your account.
              </p>

              <h4>Games of Skill</h4>
              <p>Only games of skill are hosted on the JustGame Platform.</p>
              <p>Users acknowledge that the games available on the Platform are classified as games of skill under Indian law. JustGame does not support, endorse, or offer any games of chance to its Users. While the term "games of skill" is not exhaustively defined, it generally refers to games where the player’s effort, strategy, and skill significantly influence the outcome, and the role of luck is minimal.</p>
              <p>The games hosted on the Platform fall within the category of skill-based games, meaning the outcome or success in these games is primarily determined by the User’s ability, performance, and strategic thinking. The Supreme Court of India has upheld that the right to participate in games of skill is a constitutionally protected right under Indian law.</p>

              <h4>Contests, Deals, Levels and In-App purchases</h4>
              <p>JustGame may offer various contests, tournaments, and challenges ("Contests") on the Platform. Participation in these Contests is subject to the specific rules and regulations outlined within each Contest, and Users must comply with those rules to participate.</p>

              <ul>
                <li>Contests are based on games of skill, and the outcome is determined primarily by the User’s abilities, strategy, and performance. JustGame reserves the right to disqualify any User found violating the rules or engaging in any fraudulent activities during the Contests.
                </li>
                <li>The Company may also withdraw, modify, or terminate any Contest at its sole discretion without prior notice and will not be liable for any loss or inconvenience caused to Users by such actions.</li>
              </ul>

              <p>From time to time, JustGame may offer promotional deals or discounts ("Deals") to Users. These Deals may be time-limited, subject to specific terms, or available only to certain categories of Users. Users participating in these Deals agree to abide by the applicable terms, and JustGame reserves the right to cancel, modify, or terminate any Deal without prior notice. Any misuse or abuse of the Deals may result in disqualification from the Deal and other penalties, including suspension or termination of User accounts.
              </p>

              <p>The Platform may feature different levels or stages within the games that Users can progress through based on their performance ("Levels"). These Levels may come with additional benefits, rewards, or enhanced gaming experiences.</p>

              <ul>
                <li>JustGame reserves the right to modify or change the structure of Levels, rewards, and the manner in which they are achieved, without prior notice.</li>
                <li>Any rewards or benefits offered at different Levels are non-transferable, non-assignable, and may have expiration periods as determined by the Company.</li>
              </ul>

              <p>
              JustGame may offer Users the option to make in-app purchases for virtual items, currencies, or other game-related content ("In-App Purchases"). These In-App Purchases are non-refundable, non-transferable, and cannot be redeemed for cash or any other tangible value.
              </p>
              <ul>
                <li>All In-App Purchases are governed by these Terms, and Users agree that they will not engage in fraudulent transactions, attempt to circumvent payment systems, or seek refunds for purchases unless there is a proven technical error on the part of the Platform.</li>
                <li>The prices for In-App Purchases are displayed on the Platform, and JustGame reserves the right to modify pricing, availability, or the content of such purchases at any time, without prior notice.
                </li>
                <li>In-App Purchases may be processed through third-party payment gateways, and Users must familiarize themselves with the payment provider's terms and conditions, as JustGame is not responsible for the security or processing of such transactions.
                </li>
              </ul>
              <p>
              JustGame is not liable for any damages or losses arising from the User’s participation in Contests, use of Deals, progression through Levels, or In-App Purchases. All transactions, whether financial or virtual, are conducted at the User’s own risk. JustGame reserves the right to revoke or restrict access to any of the Contests, Deals, Levels, or In-App Purchases, or any other part of the Services, for any User found to be in breach of these Terms or for any other reason at its sole discretion.
              </p>

              <p>
              By participating in Contests, Deals, Levels, or making In-App Purchases, you agree to these terms and acknowledge that JustGame has the right to modify, restrict, or terminate any of these offerings at any time, without liability to you.
              </p>

              <h4>Third Party Sites, Services and Products</h4>
              <p>
              The Platform may contain links to websites, services, or content owned and operated by third parties. By using third-party links, Users agree to be bound by the terms, conditions, and policies of those third-party sites.
              </p>
              <p>JustGame does not control, endorse, or take responsibility for any third-party websites, services, or content accessed through the Platform.</p>
              <p>
              Users' interaction with third-party platforms, including accessing links, services, or engaging in transactions, is at their own risk and discretion. JustGame is not liable for the content, accuracy, legality, or reliability of any third-party websites or services, and inclusion of links does not imply endorsement.
              </p>
              <p>
              Users are solely responsible for any correspondence, transactions, or interactions with third-party service providers, such as payment processors and verification services. Users must review and agree to the third party’s terms, conditions, and privacy policies before engaging in any transactions or offers.

              </p>
              <p>
              JustGame shall not be held responsible for any disputes, issues, or damages arising from transactions or services provided by third parties. Any claims, complaints, or questions regarding third-party products or services should be directed to the respective vendor or service provider.

              </p>
              <p>
              JustGame may contain links to other websites owned and operated by third parties. JustGame does not guarantee the accuracy, reliability, or quality of third-party content and Users agree not to rely on such content for any purpose, including participation in Games or contests.
              </p>
              <p>
              Users acknowledge that third-party content may not be fully accurate or reliable and assume all risks associated with its use.
              </p>

              <h4>Intellectual Property</h4>
              <p>
              The Platform comprises a combination of content created by JustGame, its partners, affiliates, licensors, associates, and/or users. All intellectual property rights (IPR) in the software, games, and materials published on the Platform—including but not limited to games, software, advertisements, written content, photographs, graphics, images, illustrations, logos, marks, audio or video clippings, and animations—are owned by JustGame, its affiliates, partners, licensors, and/or associates.
              </p>
              <p>Users are prohibited from modifying, publishing, transmitting, participating in the transfer or sale of, reproducing, creating derivative works of, distributing, publicly performing, publicly displaying, or otherwise exploiting any material or content on the Platform, in whole or in part, without express written consent from JustGame. No provision in these Terms shall be interpreted to grant ownership or other rights in any intellectual property to users.
              </p>
              <p>
              Users may request permission to use any content on the Platform by writing to care@justgame.com. Any unauthorised use of the Platform’s content will be treated as a violation of JustGame’s intellectual property rights.
              </p>
              <p>
              Users are solely responsible for the content they upload, post, transmit, or otherwise make available on the Platform (User Content). Users affirm that they own all intellectual property rights in the User Content and confirm that no part of their content infringes third-party rights. Users agree not to display or use names, logos, marks, trademarks, copyrights, or other proprietary rights belonging to third parties on the Platform without proper authorization.
              </p>
              <p>
              Users agree to indemnify and hold harmless JustGame, its directors, officers, employees, affiliates, and assigns from any costs, damages, or losses, including legal fees, arising from third-party claims related to intellectual property rights violations caused by the use or display of third-party content by users on the Platform. JustGame reserves the right to assume the exclusive defense in any intellectual property dispute and request users' assistance in asserting available defenses.
              </p>
              <p>By posting User Content, users grant JustGame and its affiliates, partners, licensors, and associates a worldwide, irrevocable, perpetual, royalty-free, non-exclusive, sub-licensable license to use, store, reproduce, create derivative works of, distribute, publicly perform, publicly display, transfer, transmit, and/or publish User Content for the following purposes:
              </p>
              <ul>
                <li>Displaying User Content on the Platform.</li>
                <li>Distributing User Content electronically or via other media to users.</li>
                <li>Storing User Content in a remote database accessible to users for a charge.
                </li>
              </ul>
              <p>This license extends to the distribution and storage of User Content across all forms, mediums, or technologies and will not lapse due to non-use by JustGame.</p>

              <p>All names, logos, marks, trademarks, copyrights, or intellectual property rights on the Platform that belong to third parties are recognised as proprietary to their respective owners. Any claims, disputes, or issues regarding third-party intellectual property must be directly addressed with the respective owner, under notice to JustGame.
              </p>
              <p>All rights, title, and interest in and to the Services, remain JustGame’s exclusive property, and/or of its licensors. Services are protected by copyright, trademark, and other laws. Nothing in the Terms gives Users any right to use the JustGame name or any of the JustGame trademarks, logos, domain names, properties and other distinctive brand features, without JustGame’s prior written consent.
              </p>

              <h4>Payment Terms for JustGame</h4>
              <p>Deposit in User Wallet:</p>
              <ul>
                <li>In compliance with Rule 31B of the Central Goods and Services Tax Rules, 2017 (CGST Rules), effective from October 1, 2023, JustGame shall levy applicable GST on the amounts deposited by the User.</li>
                <li>The Company will issue an invoice for each deposit, detailing the taxable value and applicable GST.</li>
                <li>If deposits are made via UPI, JustGame may store the Virtual Payment Address (VPA) to enhance future payment experiences. Users consent to this storage.</li>
                <li>JustGame may retrieve additional VPAs linked to the User’s registered mobile number to streamline transactions, and Users consent to this retrieval and storage.
                </li>
              </ul>

              <p>Payment Terms for Games and Contests:
              </p>
              <ul>
                <li>Users agree to pay contributions for each game, which include:
                  <ul>
                    <li>A Platform Fee for access to the game.</li>
                    <li>A Contribution towards the Prize Money Pool for the game, which will be awarded to winners as per the game's rules.</li>
                  </ul>
                </li>
                <li>The Company reserves the right to deny access to the platform and disqualify any User at its sole discretion.</li>
              </ul>
              <h4>Role of Payment Collection Agent:</h4>
              <ul>
                <li>Users appoint ___________ (or a designated third party) as their payment collection agent to manage funds on their behalf.
                </li>
                <li>User funds are held in a non-interest-bearing account managed by the Agent.</li>
                <li>The Company does not have any right over the Prize Money Pool, which is managed by the Agent, except for its share of Platform Fees.</li>
              </ul>
              <h4>User Account Structure:
              </h4>
              <ul>
                <li><strong>Play Money:</strong> Money deposited by the User.</li>
                <li><strong>Prize Money:</strong> Winnings earned by Users, which can be withdrawn or used to play more games, after applicable taxes.</li>
                <li><strong>Cashbacks:</strong> Discounts offered by JustGame. These are non-withdrawable and can be used to avail discounts but cannot be redeemed for cash.</li>
                <li><strong>Bonus Money:</strong> Discounts offered in the form of bonus points. These are non-withdrawable and can only be adjusted against Platform Fees.</li>
                <li><strong>Entry Pass:</strong> A non-withdrawable discount scheme offering Users discounts on entry fees, as per the terms communicated by the Company.</li>
                <li><strong>Special Rewards:</strong> Non-withdrawable discounts offered on certain games or contests, used to avail discounts on entry fees.</li>
              </ul>

              <h4>Withdrawal Process:</h4>

              <ul>
                <li>Users can request withdrawals from their <strong>Play Money</strong> after completing user verification (including PAN card and bank account verification).
                </li>
                <li>Withdrawals will be processed within a reasonable time after all required documents are verified.</li>
                <li><strong>Prize Money</strong> withdrawals are subject to a nominal processing fee, and TDS may be applicable as per current tax laws.</li>
              </ul>

              <p>
              Pursuant to Rule 31B of the CGST Rules, the amount available for withdrawal from the Play Money account will be net of GST on deposits made after October 1, 2023.

              </p>
              <p>
              The Company, at its discretion, may issue refunds for cancellations or exceptional circumstances, after deducting applicable taxes and GST.

              </p>

              <h4>TDS Deduction:
              </h4>

              <ul>
                <li>As per the Income Tax Act, 1961, TDS will be deducted on <strong>Net Winnings</strong> at the time of withdrawal or at the end of the financial year, whichever is earlier.
                </li>
                <li>TDS will be deducted at a rate of 30% on the Net Winnings.
                </li>
                <li>Users will be issued a TDS certificate (Form 16A) for their records.</li>
              </ul>

              <h4> Scheme Terms:
              </h4>

              <ul>
                <li>Cashback, Bonus Money, and other discount schemes offered by JustGame do not hold any intrinsic value and cannot be redeemed for cash.</li>
                <li>The Company reserves the right to modify, cancel, or withdraw any discount schemes or contests without prior notice.
                </li>
              </ul>

              <p>The Company reserves the right to disqualify winners or deny withdrawals if the User fails to meet eligibility criteria or provides false documentation.
              </p>

              <p>The User is responsible for any additional taxes applicable beyond TDS. The Company is not liable for the quality, suitability, or merchantability of any prizes and reserves the right to modify or replace prizes at any time.
              </p>

              <h4>Limitation of Liability</h4>
              <p>
              To the fullest extent permitted by law, users access and use JustGame’s Services at their own risk. The Services and all content are provided on an “AS IS” and “AS AVAILABLE” basis. JustGame expressly disclaims all warranties and conditions, whether express or implied, including but not limited to, implied warranties of merchantability, fitness for a particular purpose, or non-infringement. JustGame makes no warranty and assumes no responsibility or liability for: (i) the accuracy, completeness, availability, timeliness, security, or reliability of the Services or any content; (ii) any damage to users’ devices, data loss, or harm arising from accessing or using the Services or content; (iii) failure to store, transmit, or delete any content or communication through the Services; (iv) whether the Services will meet user requirements, or be available uninterrupted, secure, or error-free; and (v) any errors or omissions, including those related to prizes, from JustGame or third parties.

              </p>
              <p>
              To the maximum extent permitted by applicable law, JustGame will not be liable for any indirect, incidental, special, consequential, or punitive damages, or loss of profits, revenues, data, goodwill, or other intangible losses, arising from: (i) access to, use of, or inability to access or use the Services; (ii) any conduct or content of third parties, including defamatory, offensive, or illegal conduct by other users or third parties; (iii) any content obtained from the Services; (iv) unauthorized access, use, or alteration of content; or (v) natural calamities.
              </p>
              <p>
              In no event will JustGame’s total liability to any user exceed the amount the user has paid to JustGame in the six months preceding the claim, or INR 1000, whichever is lower. This limitation applies regardless of the legal theory (warranty, contract, statute, tort, etc.) and even if JustGame has been advised of the possibility of such damages. Users are solely responsible for the consequences of accessing and using JustGame’s Services, especially if such actions involve illegal acts or non-compliance with these Terms, including providing false information. Users agree to indemnify JustGame, its officers, directors, employees, and agents for any losses, damages, or legal expenses incurred due to the user’s actions, including full attorney fees.

              </p>
              <p>
              Users agree to indemnify, defend, and hold JustGame harmless from any claims made by third parties arising from their use of the Services or participation in any contests. JustGame will not be liable for any acts or omissions related to users’ engagement or contest participation.

              </p>
              <p>
              The failure of JustGame to enforce any provision of these Terms does not constitute a waiver of that provision. If any part of these Terms is found to be invalid or unenforceable, that provision will be limited to the minimum extent necessary, and the rest of the Terms will continue to remain in effect.

              </p>
              <p>
              These Terms are governed by the laws of the Republic of India. Users acknowledge that their access to the Services may also be subject to the laws and regulations specific to their state of residence.

              </p>
              <p>
              All legal claims or disputes arising from the use of the Services will be subject to the exclusive jurisdiction of the courts of Kolkata. Users consent to the jurisdiction and waive any objections based on inconvenient forum.

              </p>

              <h4>Grievance Redressal
              </h4>
              <p>
              In accordance with the Information Technology (Intermediary Guidelines and Digital Media Ethics Code) Rules, 2021, JustGame has appointed a Grievance Officer to address user grievances related to the use of the Services, including any violation of these Terms, concerns regarding data protection, or other complaints.
              </p>
              <ul>
                <li>
                <p><strong>Grievance Officer:</strong>
                <br/>
                Name: [Grievance Officer's Name]<br/>
                Email: [Grievance Officer’s Email]<br/>
                Contact Address: [Company’s Address]<br/>
                Working Hours:
                </p>
                
                </li>
                <li>Users who have any complaints, concerns, or grievances regarding the Services, or who believe that any content posted on the platform violates these Terms or any applicable laws, may submit their complaint via email to the Grievance Officer.
                </li>
                <li>
                  The complaint should include:
                  <ul>
                    <li>User’s name and contact information (email or phone)</li>
                    <li>A detailed description of the issue, along with supporting documentation (if applicable)</li>
                    <li>Specific content or service in question (if applicable)
                    </li>
                  </ul>
                </li>
              </ul>
              <p>
              The Grievance Officer will acknowledge receipt of the complaint within 24 hours and will resolve the issue within 15 days from the date of receipt of the grievance. In case further information is required, the Grievance Officer may reach out to the complainant for clarification or additional details.

              </p>
              <p>
              If a complaint relates to the violation of any legal or community guidelines, including intellectual property infringement, defamation, or inappropriate content, JustGame reserves the right to take appropriate action, including but not limited to, removing or disabling access to the offending content. Such action will be taken within 36 hours of receiving the complaint, in accordance with applicable laws and regulations.
              </p>
              <p>
              If the user is not satisfied with the resolution provided by the Grievance Officer, the matter may be escalated by submitting an appeal to the higher authorities within JustGame, as per internal escalation policies. The user may also pursue legal remedies in accordance with the applicable laws.
              </p>
              <p>
              Users are advised to ensure that any complaints or grievances submitted are factual and provided in good faith. Filing of frivolous or misleading complaints may result in action being taken against the user, including suspension or termination of access to the Services.
              </p>
              <p>
              JustGame reserves the right to replace the Grievance Redressal Officer at its discretion through publication on its website, and such replacement will come into effect immediately upon publication.
              </p>

              <h4> Termination</h4>
              <p>
              Users may terminate their account and stop using the Services at any time by following the account deletion process provided in the Services. Upon termination, the user’s access to their account, profile, and any associated data will be permanently disabled. Any ongoing or pending contests, prizes, or in-game assets will be forfeited upon termination by the user.
              </p>
              <p>
              JustGame reserves the right, at its sole discretion, to suspend, restrict, or terminate a User’s access to all or part of the Services at any time, with or without notice, for any of the following reasons:
              </p>
              <ul>
                <li>Breach of any of the provisions of these Terms or any applicable laws or regulations.</li>
                <li>Engaging in fraudulent or unlawful behavior, including but not limited to cheating, misuse of gaming features, or submitting false information.</li>
                <li>Use of the Services in any manner that could damage, disable, overburden, or impair JustGame’s servers or networks, or interfere with other users’ enjoyment of the Services.</li>
                <li>Posting or sharing inappropriate, offensive, or illegal content, or engaging in harassment or abusive behavior towards other users or staff.
                </li>
                <li>Failure to make timely payments for any in-app purchases or services that require payment.</li>
              </ul>

              <p>
              Upon termination, the User’s account will be deactivated, and all rights granted under these Terms will immediately cease. Any ongoing participation in games or contests will be void, and the User will lose access to any winnings, rewards, or in-game purchases, without any refund.
              </p>
              <p>
              JustGame may delete or remove any user-generated content associated with the terminated account and reserves the right to retain certain user data for legal or regulatory reasons, as outlined in the Privacy Policy.
              </p>
              <p>
              The following provisions will survive the termination of the User’s account: Intellectual Property Rights, Limitation of Liability, Indemnification, Governing Law, and Dispute Resolution.

              </p>
              <p>
              In certain cases, a user may request reinstatement of a terminated account. Such requests will be reviewed on a case-by-case basis, and JustGame reserves the right to accept or reject any reinstatement request at its sole discretion.

              </p>
              <p>Unless otherwise required by law, termination of a user’s account, either by the user or by JustGame, does not entitle the user to any refunds for any amounts previously paid or for any unused portion of services or in-app purchases.
              </p>
              <p>JustGame reserves the right to discontinue or modify its Services, in whole or in part, at any time. If JustGame discontinues providing a Service that the user has paid for, the user may be entitled to a refund of the unused portion of that Service, subject to applicable laws.
              </p>

              <h4> Standard Terms
              </h4>
              <p>JustGame reserves the right to modify these Terms at any time. Users will be notified of any significant changes, and continued use of the Services constitutes acceptance of the updated Terms.
              </p>
              <p>JustGame makes no guarantee that the Services will always be available or free of interruptions, delays, or errors. JustGame is not responsible for any issues caused by third-party service providers, network outages, or unforeseen technical difficulties.
              </p>
              <p>JustGame will not be liable for any failure or delay in performance caused by factors beyond its reasonable control, including but not limited to natural disasters, war, labour strikes, internet failures, government actions, or pandemics.
              </p>
              <p>These Terms will be governed by and construed in accordance with the laws of the Republic of India, without regard to conflict of law principles.
              </p>
              <p>All disputes will be resolved through binding arbitration in Kolkata, India, in accordance with the applicable arbitration laws.
              </p>
              <p>If any provision of these Terms is found to be invalid or unenforceable by a court of competent jurisdiction, the remaining provisions will continue to be in full force and effect.</p>
              <p>These Terms constitute the entire agreement between the users and JustGame concerning the use of the Services and supersede any prior agreements or understandings.
              </p>

              









            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default Terms
