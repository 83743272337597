import React, { useState, useEffect, useRef } from "react";
import "./assets/css/style.css";
import "./assets/css/animate.css";
import JustGameLogo from "../components/assets/images/just-game-logo.png";
import Lakh from "../components/assets/images/lakh.png";
import Bancross from "../components/assets/images/ban_cross.png";
import Support from "../components/assets/images/support.png";
import Shield from "../components/assets/images/Shield.png";
import Banner from "../components/assets/images/banner.png";
import Banner1 from "../components/assets/images/banner_01.png";
import Object1 from "../components/assets/images/ob1.png";
import Object2 from "../components/assets/images/ob2.png";
import Object3 from "../components/assets/images/ob3.png";
import Object4 from "../components/assets/images/ob4.png";
import Object5 from "../components/assets/images/ob5.png";
import Object6 from "../components/assets/images/Group 30361.png";
import Object7 from "../components/assets/images/Group 2 copy 2.png";
import slash from "../components/assets/images/slash.png";
import RedemVoucher from "../components/assets/images/voucher.png";
import People from "../components/assets/images/people.png";
import cash from "../components/assets/images/cash.png";
import Accordion from "react-bootstrap/Accordion";
import Voucher from "../components/assets/images/vou.png";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Footer from "./Footer";
import OurgameInnerPage from "./OurgameInnerPage";
import woban from "../components/assets/images/wserach.png";
import word1 from "../components/assets/images/l7.png";
import w1 from "../components/assets/images/w1.png";
import w2 from "../components/assets/images/w2.png";
import w3 from "../components/assets/images/w3.png";
import w4 from "../components/assets/images/w4.png";
import { useLocation } from "react-router-dom";
import GameData from "../components/Games.json";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import axios from "axios";

function ContentInnerGame() {
  const location = useLocation();
  const navigate = useNavigate();
  // const { from } = location;
  // console.log(location?.state?.data);
  const getValue = location.state?.data;
  const { name } = useParams();

  const handleClickScroll = () => {
    const element = document.getElementById("how_to_install");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const scrollToParent = () => {
    // Navigate to the parent route
    navigate("/");

    // Use a small delay to allow the navigation to happen before scrolling
    setTimeout(() => {
      // Find the parent section and scroll into view
      const parentSection = document.querySelector("#about");

      if (parentSection) {
        parentSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };
  const download_now = "https://justgame.in/apk/justgame.apk";
  let arrayData = [
    {
      headerName: "1. How does the JustGame work?",
      bodyDetails:
        "JustGame provides a platform for users to play a variety of games and earn money. They can join tournaments by spending money and earn money by winning it.",
    },
    {
      headerName: "2. Is it free to play games on the app?",
      bodyDetails:
        "Player can try games without spending money, but to gain profit or to earn money they have to spent money to join real money tournaments.",
    },
    {
      headerName: "3. What kind of games are available on the app?",
      bodyDetails:
        "We offer a diverse range of games, including puzzles, running, action,platform,strategy, and more. But all the games are skills based. There's something for every gaming preference.",
    },
    {
      headerName: "4. Can I play the games on different devices?",
      bodyDetails:
        "Yes, our app is designed to be compatible with various devices, including IOS and Android smartphones. You can seamlessly switch between devices.",
    },
    {
      headerName: "5. Are there time restrictions for playing games? ",
      bodyDetails:
        "You can play games at any time that suits you. There are no time restrictions, allowing you to enjoy the gaming experience on your schedule.",
    },
    {
      headerName: "6. Is my personal information secure on the gaming app?",
      bodyDetails:
        "- Yes, we take user privacy and data security seriously. Your personal information is encrypted and stored securely. Refer to our privacy policy for more details.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const dataPerRow = 3;
  const [next, setNext] = useState(dataPerRow);

  const handleMoreImage = (e) => {
    e.preventDefault();
    setNext(next + dataPerRow);
  };
  const [gameDataObj, setGameDataObj] = useState(null);

  useEffect(() => {
    if (name) {
      console.log(name);
      const matchedGame = GameData.find(
        (game) => game.Game_Name.toLowerCase() == name.toLowerCase()
      );
      setGameDataObj(matchedGame);
    }
  }, [name]);

  console.log(gameDataObj);

  const [isToggle, setIsToggle] = useState(false);
  return (
    <div>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap flex-sm-nowrap">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img src={JustGameLogo} alt="Just  Game" width="180" />
              </Link>
              <nav className="main-menu">
                <ul>
                  <li className="game_all_list">
                    <a
                    //  onClick={()=>{
                    //   if(isMobile){
                    //     setIsToggle(!isToggle)
                    //   }
                    //  }}
                    >
                      Our Games <i class="fa-solid fa-angle-down"></i>
                    </a>
                    <div className="game_menu">
                      {GameData.map((item, key) => {
                        return (
                          <ul>
                            <li
                              onClick={() => {
                                navigate(`/${item?.Game_Name}`, {
                                  state: {
                                    data: item,
                                  },
                                });
                                window.scroll(0, 0);
                                // window.location.reload();
                                //  setIsToggle(!isToggle)
                              }}
                            >
                              {item?.Game_Name.replace(/-/g, " ")}
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </li>
                  <li>
                    <a onClick={scrollToParent}>About Us</a>
                  </li>
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>

      {/* <OurgameInnerPage/> */}
      {gameDataObj == null ? (
        <div>{/* <h1>hello</h1> */}</div>
      ) : (
        <div>
          <section
            className="main_banner"
            style={{ backgroundImage: `url(${Banner})` }}
          >
            <div className="container">
              <div className="row">
                <div className="col-sm-6">
                  <div className="main_ban_lt">
                    <img
                      style={{
                        paddingTop:
                          gameDataObj?.orientation == "landscape" && 120,
                      }}
                      className="animate__animated animate__fadeInLeft"
                      src={require(`../components/assets/images/${gameDataObj?.header_image}`)}
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="main_ban_rt">
                    <img
                      className="ob2_sec wow animate__animated animate__fadeInTopLeft"
                      src={Object2}
                      alt=""
                    />
                    <img
                      className="ob3_sec wow animate__animated animate__fadeInTopRight"
                      style={{ animationDelay: ".85s" }}
                      src={Object3}
                      alt=""
                    />
                    {/* <h6 className="wow animate__animated animate__shakeX">PLAY, WIN & SHOP</h6> */}
                    <h2 className="wow animate__animated animate__fadeInRight">
                      PLAY
                    </h2>
                    <div
                      className="gamehed wow animate__animated animate__fadeInUp"
                      style={{
                        animationDelay: ".95s",
                        textTransform: "uppercase",
                      }}
                    >
                      <h4>{gameDataObj?.Game_Name.replace(/-/g, " ")}</h4>
                    </div>
                    <h3 className="wow animate__animated animate__fadeInRight">
                      & WIN DAILY
                    </h3>
                    <div
                      className="lakh_sec wow animate__animated animate__zoomIn"
                      style={{ animationDelay: ".5s" }}
                    >
                      <img className="ob1_sec" src={Object1} alt="" />
                      <img src={cash} alt="" />
                    </div>

                    <img
                      className="ob4_sec innerob4_sec wow animate__animated animate__fadeInBottomLeft"
                      style={{ animationDelay: "1.15s" }}
                      src={Object4}
                      alt=""
                    />
                    <img
                      className="ob5_sec wow animate__animated animate__fadeInBottomRight"
                      style={{ animationDelay: ".95s" }}
                      src={Object5}
                      alt=""
                    />
                    <a
                      href={download_now}
                      // download={"justgame.apk"}
                    >
                      <img
                        onClick={handleClickScroll}
                        className="ob6_sec  wow animate__animated animate__zoomIn"
                        style={{ animationDelay: ".95s", cursor: "pointer" }}
                        src={Object6}
                        alt=""
                      />
                    </a>
                    {/* <div
                  className="play_store_sec wow animate__animated animate__zoomIn"
                  style={{ animationDelay: "1.55s" }}
                >
                  <img src={GooglePlay} alt="" />
                  <img src={AppStore} alt="" />
                </div> */}
                    <img className="ban_crs" src={Bancross} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="redem_sec">
            <div className="container">
              <div className="row">
                <div className="col-sm-3 col-6">
                  <div className="redem1">
                    <div className="redem_up">
                      <img draggable="false" src={Support} alt="" />
                    </div>
                    <div className="redem_dwn">
                      <h4>24X7</h4>
                      <p>SUPPORT</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-6">
                  <div className="redem1">
                    <div className="redem_up">
                      <img draggable="false" src={Shield} alt="" />
                    </div>
                    <div className="redem_dwn">
                      <h4>100%</h4>
                      <p>SECURE & LEGAL</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-6">
                  <div className="redem1">
                    <div className="redem_up">
                      <img draggable="false" src={RedemVoucher} alt="" />
                    </div>
                    <div className="redem_dwn">
                      <h4>INSTANT</h4>
                      <p>WITHDRAWALS</p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-3 col-6">
                  <div className="redem1">
                    <div className="redem_up">
                      <img draggable="false" src={People} alt="" />
                    </div>
                    <div className="redem_dwn">
                      <h4>REAL PLAYERS</h4>
                      <p>REAL ADVENTURE</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="ourgame1">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="brdcm">
                    <ul>
                      <li>
                        <a href="/">HOME </a>
                      </li>

                      <li>
                        <a href="">OUR GAMES </a>
                      </li>
                      <li>
                        <a href="" className="active">
                          {gameDataObj?.Game_Name.replace(/-/g, " ")}
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="row">
                    <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-3 col-4">
                      <div className="org_gameimg">
                        <img
                          src={require(`../components/assets/images/${gameDataObj?.image_url}`)}
                          alt=""
                        />
                      </div>
                    </div>
                    <div className="col-xxl-10 col-xl-10 col-lg-9 col-md-9 col-sm-9 col-8">
                      <div className="our_game_rt">
                        <div
                          className="our_game_rt_top"
                          style={{ textTransform: "uppercase" }}
                        >
                          <h6>{gameDataObj?.Game_Name?.replace(/-/g, " ")}</h6>
                        </div>
                        <div className="our_game_rt_dwn">
                          <div className="our_game_rt_dwn_inn">
                            <h5>PLAYERS</h5>
                            <p>{gameDataObj?.Players}</p>
                          </div>
                          <div className="our_game_rt_dwn_inn">
                            <h5>GENRES</h5>
                            <p>{gameDataObj?.Genre.join(", ")}</p>
                          </div>
                          <div className="our_game_rt_dwn_inn">
                            <h5>PLAYING TIME</h5>
                            <p>{gameDataObj?.Play_Time}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="our_game_txt">
                    <p>{gameDataObj?.Game_Description}</p>

                    {gameDataObj?.Tokens && (
                      <div className="placing_sec">
                        {/* <p>
                      <strong>Placing Buildings :</strong>Tap on the empty
                      Island Grid/Tile to place the Current Building on the Map.
                      The active building that can be placed is displayed at the
                      bottom of the screen with an animation and bubbles, This
                      is the building that will be placed on the map when
                      clicked on the grid.
                    </p> */}
                        <p>
                          <strong>Tokens : </strong>
                        </p>

                        {gameDataObj?.Tokens?.map((item, key) => {
                          return (
                            <p>
                              <strong>{`${item?.header.replace(
                                /-/g,
                                " "
                              )}`}</strong>
                              <br />
                              {item?.paragraph}
                            </p>
                          );
                        })}
                      </div>
                    )}
                    {gameDataObj?.Controls && (
                      <div className="control_sec">
                        <h6>Controls</h6>
                        {gameDataObj?.Controls?.Mobile_Controls?.map(
                          (item, key) => {
                            return <p>{key + 1 + ".  " + item}</p>;
                          }
                        )}
                      </div>
                    )}
                    <div className="control_sec">
                      {gameDataObj?.Placing_Build && (
                        <p>
                          <strong>Placing Buildings :</strong>
                          {gameDataObj?.Placing_Build}
                        </p>
                      )}
                      <p>
                        <strong>Points Calculation : </strong>
                      </p>
                      <p>{gameDataObj?.Points_Calculation} </p>
                    </div>

                    <div className="lives">
                      {/* <h6>{getValue.lives}</h6>
                    <p>{getValue.livestext}</p> */}
                    </div>

                    <div className="bonus_points">
                      <h6>Bonus Points</h6>
                      <ul>
                        {gameDataObj?.Bonus_Points.map((item, key) => (
                          <li>{item}</li>
                        ))}
                      </ul>
                    </div>
                    <div className="control_sec">
                      <p>
                        <strong>{`Is ${gameDataObj?.Game_Name.replace(
                          /-/g,
                          " "
                        )} Safe to Download?`}</strong>
                      </p>
                      <p>{gameDataObj?.to_Download} </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="screenshot_sec">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="screenshot_inn">
                    <h6 style={{ textTransform: "uppercase" }}>
                      HOW TO PLAY {gameDataObj?.Game_Name?.replace(/-/g, " ")}{" "}
                      ON JUST GAME
                    </h6>
                    <div className="row">
                      {gameDataObj?.how_to_play_images?.map((item) => {
                        return (
                          <div className="col-sm-3 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                            <div className="screenshot_inn_img">
                              <img
                                src={require(`../components/assets/images/${item?.image}`)}
                                alt=""
                              />
                              <p>{item?.title}</p>
                            </div>
                          </div>
                        );
                      })}
                    </div>

                    <div className="word_details">
                      {gameDataObj?.How_to_Play.map((item, key) => (
                        <p> {key + 1 + ".  " + item}</p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="tricks_sec">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="tricks_inn">
                    <h6 style={{ textTransform: "uppercase" }}>
                      TIPS & TRICKS -{" "}
                      {gameDataObj?.Game_Name?.replace(/-/g, " ")} GAME
                    </h6>
                    {gameDataObj?.Tips_and_Tricks?.map((item, key) => (
                      <div className="tricks_para">
                        <p>{key + 1 + ".  " + item}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
      <section className="how_to_install_sec" id="how_to_install">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h6>HOW TO INSTALL</h6>
              <p className="how_to_install mb-4">
                FOLLOW THE STEPS BELOW TO START PLAYING JUST GAME ON YOUR
                ANDROID DEVICE NOW!
              </p>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3 h-100 justify-content-between border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    1
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      click on the
                      <span
                        className="text-start"
                        style={{ fontWeight: "bold" }}
                      >
                        "Download App"
                      </span>
                      button below.
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <a
                    href={download_now}
                    // download={"justgame.apk"}
                  >
                    <img src={Object6} className="w-50 mb-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3  border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    2
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      On your phone <br />
                      click{" "}
                      <span
                        className="text-center text-md-start"
                        style={{ color: "#340764", fontWeight: "bold" }}
                      >
                        "Download Anyway"
                      </span>{" "}
                      to start downloading.
                    </p>
                  </div>
                </div>
                <div className="text-md-start text-center ps-0 ps-md-5 ms-0 ms-md-5 mt-3">
                  <img src={Object7} className="w-50" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3 h-100 border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    3
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p mb-3"
                      style={{ color: "#340764" }}
                    >
                      Once it's downloaded, Install and Open the app, and sign
                      up.
                    </p>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      Tap Continue to allow customization for better experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3 h-100 border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    4
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      Enter your phone number, verify with OTP,
                      <br /> and use any referral code to get extra rewards.
                      <br />
                      <span style={{ color: "#340764", fontWeight: "bold" }}>
                        START PLAYING!
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq_sec" id="games">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h6>FAQS</h6>
              {/* <Accordian title="1. How does the JustGame work?" content="JustGame provides a platform for users to play a variety of free games and earn points. These points can be redeemed for shopping vouchers."  />
        <Accordian
          title="2. Is it really free to play games on the app?"
          content="Second Accordion content"
          
        />
        <Accordian
          title="3. How do I earn points while playing games?"
          content="Third Accordion content"
          
        />
        <Accordian
          title="4. What kind of games are available on the app?"
          content="Fourth Accordion content"
          
        />
        <Accordian
          title="5. Can I play the games on different devices?"
          content="Five Accordion content"
          
        />
        <Accordian
          title="6. How do I redeem my points for shopping vouchers?"
          content="Six Accordion content"
          
        /> */}

              <Accordion defaultActiveKey="0">
                {arrayData?.slice(0, next)?.map((item, key) => {
                  return (
                    <Accordion.Item eventKey={key}>
                      <Accordion.Header>{item.headerName}</Accordion.Header>
                      <Accordion.Body>{item.bodyDetails}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
                {/* <Accordion.Item eventKey="0">
                  <Accordion.Header>1. How does the JustGame work?</Accordion.Header>
                  <Accordion.Body>
                    JustGame provides a platform for users to play a variety of free games and earn points. These points can be redeemed for shopping vouchers.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>2. Is it really free to play games on the app?</Accordion.Header>
                  <Accordion.Body>
                    Yes, all the games on our app are completely free to play. You can enjoy gaming without any upfront costs.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>3. How do I earn points while playing games?</Accordion.Header>
                  <Accordion.Body>
                    You earn points by achieving high scores and completing in-game challenges via playing different battle, league & tournament. The more you play and excel, the more points you accumulate.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>4. What kind of games are available on the app?</Accordion.Header>
                  <Accordion.Body>
                    We offer a diverse range of games, including puzzles, running, action,platform,strategy, and more. There's something for every gaming preference.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>5. Can I play the games on different devices?</Accordion.Header>
                  <Accordion.Body>
                    Yes, our app is designed to be compatible with various devices, including ios and android smartphones. You can seamlessly switch between devices.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>6. How do I redeem my points for shopping vouchers?</Accordion.Header>
                  <Accordion.Body>
                    Once you've accumulated enough points, you can visit the app's rewards section. There, you'll find options to redeem your points for a variety of shopping vouchers from popular retailers.
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>

              {next < arrayData?.length && (
                <div className="load_more" onClick={handleMoreImage}>
                  <a href="">LOAD MORE</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default ContentInnerGame;
