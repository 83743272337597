import React, { useEffect } from "react";
import Thinktrek from "../components/assets/images/thinktrek.png";
import JustGameLogo from "../components/assets/images/just-game-logo.png";
import GooglePlay from "../components/assets/images/google-play.png";
import AppStore from "../components/assets/images/app-store.png";
import Skill1 from "../components/assets/images/SKIL_01.png";
import Skill2 from "../components/assets/images/SKIL_02.png";
import Chair from "../components/assets/images/chair.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Header from "./Header";
const Skill = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
   <Header />
      <section className="privacy_sec">
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">SKILL CERTIFICATE</h2>

              <p>
                Welcome to Just Game, where skill earns recognition! We are
                proud to announce that our games have been officially certified
                as skill-based by Dr. Bimal Roy.
              </p>
              <p>
                Dr. Roy, Former Director of the Indian Statistical Institute,
                Kolkata, and Former Chairperson of the National Statistical
                Commission, Government of India, has lent his expertise to
                validate the skill-based nature of our games.
              </p>
              <p>
                Using rigorous statistical methods, his team conducted an
                in-depth evaluation to measure how skill influences game
                outcomes. Through a combination of simulation-based and
                data-driven analyses, they developed six quantitative tests
                grounded in Indian and global jurisprudence to assess “skill” in
                online gaming.
              </p>

              <p>Tested games are:</p>
              <div className="row">
                <div className="col-sm-6">
                  <ul>
                    <li>Hit It Right</li>
                    <li>Copter Ride</li>
                    <li>Jump It Right</li>
                    <li>Super Monkey</li>
                    <li>Super Bird</li>
                    <li>Tap Tap Shot</li>
                    <li>Word Search</li>
                  </ul>
                </div>
                <div className="col-sm-6">
                  <ul>
                    <li>Rolling Cart</li>
                    <li>Fruit Chop</li>
                    <li>Connect The Ball</li>
                    <li>Match It Right</li>
                    <li>Word Connect</li>
                    <li>Solitaire Flash</li>
                    <li>Merge City</li>
                  </ul>
                </div>
              </div>

              <div className="certifi">
                <img className="w-100" src={Skill1} alt="" />
              </div>

              <div className="certifi mt-2">
                <img className="w-100" src={Skill2} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Skill;
