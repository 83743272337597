import React, { useEffect } from 'react'
import Thinktrek from '../components/assets/images/thinktrek.png'
import JustGameLogo from '../components/assets/images/just-game-logo.png'
import GooglePlay from '../components/assets/images/google-play.png'
import AppStore from '../components/assets/images/app-store.png'
import Chair from '../components/assets/images/chair.png'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import Header from './Header'
const Support = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
       <Header />
    <section className="support_sec">
      <div class="container">
        <div class="row">
          <div className='col-sm-6'>
          <div className='supp_chair'>
              <img src={Chair} alt=''/>
            </div>
          </div>
          <div class="col-sm-6 text-white fs-5 supp_rt_inn">
            <h2 class="text-center supp_txt">FOR SUPPORT INFORMATION</h2>
            <p class="text-center supp_sml" >
            FOR ANY SUPPORT INQUIRY PLEASE CONTACT<br /><br />
              <a class="text-yellow supp_mail" href="mailto:support@thinktrekent.in"
                >support@thinktrekent.in</a
              >
            </p>
          </div>
        </div>
      </div>
    </section>
<Footer/>
    </div>
  )
}

export default Support
