import React, { useState, useEffect, useRef } from "react";
import "./assets/css/style.css";
import "./assets/css/animate.css";
import "./assets/css/video-react.css";
import JustGameLogo from "../components/assets/images/just-game-logo.png";
import GooglePlay from "../components/assets/images/google-play.png";
import AppStore from "../components/assets/images/app-store.png";
import Banner from "../components/assets/images/banner.png";
import Banner1 from "../components/assets/images/banner_01.png";
import GAMES1 from "../components/assets/images/01_just_chess.png";
import GAMES2 from "../components/assets/images/02_Super_monkey.png";
import GAMES3 from "../components/assets/images/03_snake&ladder.png";
import GAMES4 from "../components/assets/images/04_space_shooter.png";
import GAMES5 from "../components/assets/images/05_copter_ride.png";
import GAMES6 from "../components/assets/images/06_fruit_chop.png";
import GAMES7 from "../components/assets/images/07_connect_the_ball.png";
import GAMES8 from "../components/assets/images/08_sweet_candy_shop.png";
import GAMES9 from "../components/assets/images/09_Hit_it_right.png";
import GAMES10 from "../components/assets/images/10_super_bird.png";
import GAMES11 from "../components/assets/images/11_jump_it_right.png";
import GAMES12 from "../components/assets/images/12_dead_zone.png";
import GAMES13 from "../components/assets/images/13_match_it_right.png";
import GAMES14 from "../components/assets/images/14_quick_dash.png";
import GAMES15 from "../components/assets/images/15_fast_roller.png";
import GAMES16 from "../components/assets/images/16_pool_master.png";
import cash from "../components/assets/images/cash.png";
import Object1 from "../components/assets/images/ob1.png";
import Object2 from "../components/assets/images/ob2.png";
import Object3 from "../components/assets/images/ob3.png";
import Object4 from "../components/assets/images/ob4.png";
import Object5 from "../components/assets/images/ob5.png";
import Object6 from "../components/assets/images/Group 30361.png";
import Object7 from "../components/assets/images/Group 2 copy 2.png";
import Client from "../components/assets/images/clnt1.png";
import Client1 from "../components/assets/images/clnt2.png";
import Client2 from "../components/assets/images/clnt3.png";
import Gpay from "../components/assets/images/flipcart.png";
import Amazon from "../components/assets/images/amazon.png";
import PhonePay from "../components/assets/images/phonepay.png";
import paytm from "../components/assets/images/paytm.png";
import Lakh from "../components/assets/images/lakh.png";
import Bancross from "../components/assets/images/ban_cross.png";
import Support from "../components/assets/images/support.png";
import Shield from "../components/assets/images/Shield.png";
import Rating from "../components/assets/images/rating.png";
import RatingBig from "../components/assets/images/rating1.png";
import RedemVoucher from "../components/assets/images/voucher.png";
import People from "../components/assets/images/people.png";
import Voucher from "../components/assets/images/vou.png";
import Battle from "../components/assets/images/battle.svg";
import PlayWithFriends from "../components/assets/images/play-with-friends.svg";
import Ranking from "../components/assets/images/ranking.svg";
import TOURNAMENT from "../components/assets/images/TOURNAMENT.svg";
import Thinktrek from "../components/assets/images/thinktrek.png";
import top1 from "../components/assets/images/l1.png";
import top2 from "../components/assets/images/l2.png";
import top3 from "../components/assets/images/l3.png";
import top4 from "../components/assets/images/l4.png";
import top5 from "../components/assets/images/l5.png";
import top6 from "../components/assets/images/l6.png";
import top7 from "../components/assets/images/l7.png";
import top8 from "../components/assets/images/l8.png";
import top9 from "../components/assets/images/l9.png";
import top10 from "../components/assets/images/l10.png";
import top11 from "../components/assets/images/l11.png";
import top12 from "../components/assets/images/l12.png";
import top13 from "../components/assets/images/l13.png";
import top14 from "../components/assets/images/l14.png";
import top15 from "../components/assets/images/l15.png";
import woban from "../components/assets/images/wserach.png";
import { Link, Navigate, useNavigate } from "react-router-dom";
import Footer from "./Footer";
import Footer1 from "./Footer (copy)";
import Slider from "react-slick";
// import Accordian from './Accordian'
import Accordion from "react-bootstrap/Accordion";
import ProgressBar from "react-bootstrap/ProgressBar";
import VideoBox from "./VideoBox";
import CustomArrows from "./slider";
// import Micky1 from "../components/assets/images/Just Chess.mp4";
// import Micky2 from "../components/assets/images/Supermonkey.mp4";
// import Micky3 from "../components/assets/images/Snakes and Ladders.mp4";
// import Micky4 from "../components/assets/images/SpaceShooter.mp4";
// import Micky5 from "../components/assets/images/copter_ride.mp4";
// import Micky6 from "../components/assets/images/FruitChop.mp4";
// import Micky7 from "../components/assets/images/connecttheball.mp4";
// import Micky8 from "../components/assets/images/SweetCandyshop.mp4";
// import Micky9 from "../components/assets/images/hit_it_right.mp4";
// import Micky10 from "../components/assets/images/SuperBird.mp4";
// import Micky11 from "../components/assets/images/jump_it_right.mp4";
// import Micky12 from "../components/assets/images/Dead_Zonee.mp4";
// import Micky13 from "../components/assets/images/Match_it_right.mp4";
// import Micky14 from "../components/assets/images/Quickdash.mp4";
// import Micky15 from "../components/assets/images/Fastroller.mp4";
import Micky16 from "../components/assets/images/07_connect_the_ball.png";
import w1 from "../components/assets/images/w1.png";
import w2 from "../components/assets/images/w2.png";
import w3 from "../components/assets/images/w3.png";
import w4 from "../components/assets/images/w4.png";
import c1 from "../components/assets/images/c1.png";
import c2 from "../components/assets/images/c2.png";
import c3 from "../components/assets/images/c3.png";
import c4 from "../components/assets/images/c4.png";
import su1 from "../components/assets/images/su1.png";
import su2 from "../components/assets/images/su2.png";
import su3 from "../components/assets/images/su3.png";
import su4 from "../components/assets/images/su4.png";
import j1 from "../components/assets/images/j1.png";
import j2 from "../components/assets/images/j2.png";
import j3 from "../components/assets/images/j3.png";
import j4 from "../components/assets/images/j4.png";
import r1 from "../components/assets/images/r1.png";
import r2 from "../components/assets/images/r2.png";
import r3 from "../components/assets/images/r3.png";
import r4 from "../components/assets/images/r4.png";
import h1 from "../components/assets/images/h1.png";
import h2 from "../components/assets/images/h2.png";
import h3 from "../components/assets/images/h3.png";
import h4 from "../components/assets/images/h4.png";
import sb1 from "../components/assets/images/sb1.png";
import sb2 from "../components/assets/images/sb2.png";
import sb3 from "../components/assets/images/sb3.png";
import sb4 from "../components/assets/images/sb4.png";
import { Player } from "video-react";
import GameData from "../components/Games.json";

// https://www.youtube.com/embed/gCd2gFuLsOo?si=JiVltQ8iaNvd7KEZ" title="YouTube video player

// const src =
// "https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4";

// thumbnail click video change
const JustGameWeb = () => {
  const navigate = useNavigate();
  const dataPerRow = 3;
  let sliderRef = useRef(null);
  let videoPlayer = useRef();
  // let arrImg = [
  //   { img: GAMES1, video: Micky2 },
  //   { img: GAMES2, video: Micky1 },
  //   { img: GAMES3, video: Micky3 },
  //   { img: GAMES4, video: Micky4 },
  //   { img: GAMES5, video: Micky5 },
  //   { img: GAMES6, video: Micky6 },
  //   { img: GAMES7, video: Micky7 },
  //   { img: GAMES8, video: Micky8 },
  //   { img: GAMES9, video: Micky9 },
  //   { img: GAMES10, video: Micky10 },
  //   { img: GAMES11, video: Micky11 },
  //   { img: GAMES12, video: Micky12 },
  //   { img: GAMES13, video: Micky13 },
  //   { img: GAMES14, video: Micky14 },
  //   { img: GAMES15, video: Micky15 },
  //   { img: GAMES16, video: "" },
  // ];
  let _dataProgressBar = [
    { label: 5, value: 80 },
    { label: 4, value: 60 },
    { label: 3, value: 40 },
    { label: 2, value: 25 },
    { label: 1, value: 10 },
  ];
  const handleClickScroll = () => {
    const element = document.getElementById("how_to_install");
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
  const [gameSlider, setGameSlider] = useState(0);
  const [counter, setCounter] = useState(13000);
  const [next, setNext] = useState(dataPerRow);
  const download_now = "https://justgame.in/apk/justgame.apk";
  let arrayData = [
    {
      headerName: "1. How does the JustGame work?",
      bodyDetails:
        "JustGame provides a platform for users to play a variety of games and earn money. They can join tournaments by spending money and earn money by winning it.",
    },
    {
      headerName: "2. Is it free to play games on the app?",
      bodyDetails:
        "Player can try games without spending money, but to gain profit or to earn money they have to spent money to join real money tournaments.",
    },
    {
      headerName: "3. What kind of games are available on the app?",
      bodyDetails:
        "We offer a diverse range of games, including puzzles, running, action,platform,strategy, and more. But all the games are skills based. There's something for every gaming preference.",
    },
    {
      headerName: "4. Can I play the games on different devices?",
      bodyDetails:
        "Yes, our app is designed to be compatible with various devices, including IOS and Android smartphones. You can seamlessly switch between devices.",
    },
    {
      headerName: "5. Are there time restrictions for playing games? ",
      bodyDetails:
        "You can play games at any time that suits you. There are no time restrictions, allowing you to enjoy the gaming experience on your schedule.",
    },
    {
      headerName: "6. Is my personal information secure on the gaming app?",
      bodyDetails:
        "- Yes, we take user privacy and data security seriously. Your personal information is encrypted and stored securely. Refer to our privacy policy for more details.",
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCounter((counter) => counter + 1);
  //   }, 1000);

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);

  const changeSlide = (position) => {
    setGameSlider(position);
  };
  // thumbnail click video change

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    // console.log(onClick);
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "red" }}
        onClick={onClick}
      >
        {/* <i class="fa-solid fa-chevron-right"  ></i> */}
      </div>
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", background: "green" }}
        onClick={onClick}
      >
        {/* <i class="fa-solid fa-chevron-left"   ></i> */}
      </div>
    );
  }

  //  let settings = {
  //             dots: true,
  //             infinite: true,
  //             slidesToShow: 7,
  //             slidesToScroll: 1,
  //              arrows:true,
  //              autoplay: true,
  //     // dots: true,
  //     //   // infinite: true,
  //     //   speed: 1000,
  //     //   autoplaySpeed: 0,
  //     //   slidesToShow: 7,
  //       // slidesToScroll: 1,
  //       // arrows:false,
  //         };

  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    arrow: false,

    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          infinite: true,
          slidesToShow: 7,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: true,
        },
      },

      {
        breakpoint: 600,
        settings: {
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          dots: true,
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: true,
        },
      },
    ],
  };

  // testimonial slider script
  var review = {
    dots: true,
    draggable: true,
    autoplay: true,
    // autoplaySpeed: 800,
    autoplaySpeed: 10000,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    touchThreshold: 1000,
    // prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          dots: true,
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          arrow: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          dots: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          arrow: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          autoplay: true,
          dots: true,
          arrow: false,
        },
      },
    ],
  };

  const handleMoreImage = (e) => {
    e.preventDefault();
    setNext(next + dataPerRow);
  };

  const [progressNow, setProgressNow] = useState(0);
  // const updateProgressNowHandler = setInterval(() => {
  //   if (progressNow >= 70) {
  //     setProgressNow(70);
  //     clearInterval(updateProgressNowHandler);
  //   }
  //   setProgressNow((s) => s + 1);
  // }, 50);

  const bannerdata = [
    {
      gametopic: woban,
      gamehed: "HIT IT RIGHT",
      logo: top1,
      players: "SINGLE PLAYERS",
      genrer: "HYPERCASUAL, SHOOT, KNIFE",
      plaing_time: "3 MIN",
      gameDescription:
        "refine your knife slinging skills. Send knives flying through the sky towards the practice board and try to stick the landing. You must aim not to hit another knife or obstacles on the board. So get on and prove that you are the best sharp shooter out there!",

      screenShort: [
        { sort: h1, title: "Select Hit it Right game from Tournament list" },
        { sort: h2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: h3, title: "Choose account option to pay the entry fee" },
        { sort: h4, title: "Enjoy the game" },
      ],

      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Hit it Right, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts. ",
        },
        {
          desc: "Score as much points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
      ],

      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "Stay Focused and try not to hurry, the game is physics based and the rotation of the board changes randomly, stay focused and hit the board when there is ample space on it or when the board slows down significantly giving you a higher chance at gaining the point.  ",
        },
        {
          parahed: "heading2",
          paradesc:
            "Try to hit the collectibles on the boards and use them to your advantage.",
        },
      ],

      control: "Mobile Controls",
      control1: "Tap on screen once to shoot a knife at the board.",

      controlpoint1:
        "Once all knives are exhausted and stick on the board, the game moves onto the next level, 1 point is awarded each time a knife sticks to the board. The Game is concluded when the knife collides with any obstacle on the board or another knife.",

      bonuspoints: [
        {
          bonus:
            "In the game you will come across apples scattered across the board, when the board is turning, try hitting those with the knives. Hitting apples will grant additional points.",
        },
        {
          bonus:
            "If you see a clock symbol on the board hit it to slow down the rotation of the board.",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "COPTER RIDE",
      logo: top2,
    },
    {
      gametopic: woban,
      gamehed: "JUMP IT RIGHT",
      logo: top3,
      players: "SINGLE PLAYERS",
      genrer: "ENDLESS RUNNER, CLICKER",
      plaing_time: "3 MIN",
      gameDescription:
        "Time your clicks and test your hand eye coordination and help our monkey friend propel through the dangerous jungle, keep giving him boost to not make him fall face down to the ground.",
      screenShort: [
        { sort: j1, title: "Select Jump it Right game from Tournament list" },
        { sort: j2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: j3, title: "Choose account option to pay the entry fee" },
        { sort: j4, title: "Enjoy the game" },
      ],
      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Jump it Right, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts. ",
        },
        {
          desc: "Score as much points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
      ],

      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "Try to collect as many bananas in your run to get the most amount of points.Normal Run Mode : 1 Banana = 1 Points",
        },
        {
          parahed: "heading2",
          paradesc:
            "When you see a Green Gem, Collect them to get a score boost within the game, essentially for 15seconds you will get double the score for every banana collected while the Green Gem has been collected.1 Banana = 2 Points",
        },
        {
          parahed: "heading3",
          paradesc:
            "Focus on upcoming obstacles rather than focusing on the character itself, if you know what’s to come next you can plan your jumps easily.",
        },
      ],

      control: "", //"Mobile Controls",
      control1: "Tap to propel the monkey left or right.",

      controlpoint1:
        "Collect bananas on its way to gather points that adds towards the in game score.",

      bonuspoints: [
        {
          bonus:
            "Collect Wooden Crates to activate DK Mode and get Score Booster and score additional points and increases the in-game score.",
        },
        {
          bonus:
            "You may come across, special Green Gems occasionally within the game, try to collect as many as possible, these gems grant additional Points when collected.",
        },
        {
          bonus:
            "God Berries can be often found in the Level along with bananas, they are represented by an arm symbol, grab these  when they appear to gain immortality for a 10 seconds.",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "SUPER MONKEY",
      logo: top4,
      players: "SINGLE PLAYERS",
      genrer: "ENDLESS RUNNER, PLATFORMER",
      plaing_time: "3 MIN",
      gameDescription:
        "An endless runner, platformer game that you can play with one hand. You control a cheeky monkey by tapping as he constantly runs forward. You time your taps to pull off stylish jumps, midair spins, and slide to gather bananas. Beware of Traps and obstacles.",
      screenShort: [
        { sort: su1, title: "Select Super Monkey game from Tournament list" },
        { sort: su2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: su3, title: "Choose account option to pay the entry fee" },
        { sort: su4, title: "Enjoy the game" },
      ],
      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Super Monkey, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts.",
        },
        {
          desc: "Score as much points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
        {
          desc: "Try to collect as many bananas while jumping and sliding across the jungle, avoid the obstacles that come your way or the game ends.",
        },
      ],
      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "Try to collect as many bananas in your run to get the most amount of points.",
        },
        {
          parahed: "heading2",
          paradesc:
            "Normal Run Mode : 1 Banana = 10 Points DK Mode : 1 Banana = 20 Points",
        },
        {
          parahed: "heading3",
          paradesc:
            "Focus on upcoming obstacles rather than focusing on the character itself, if you know what’s to come next you can plan your jumps and slides easily.",
        },
      ],

      control: "Mobile Controls",
      control1: "Single Tap to Jump",
      control2: "Double Tap to Double Jump and Vault",
      control3: "Swipe to Slide under obstacles",

      controlpoint1:
        "Everytime the monkey collects a banana, 10 points are granted towards the ingame score.",

      bonuspoints: [
        {
          bonus:
            "Collect Wooden Crates to activate DK Mode and get Score Booster and score additional points and increases the in-game score.",
        },
        {
          bonus:
            "You may come across, special Green Gems occasionally within the game, try to collect as many as possible, these gems grant additional Points when collected.",
        },
        {
          bonus:
            "Score Multipliers can be often found in the Level along with bananas, they are represented by 2x, 3x,  4x, grab these  when they appear to gain an additional score boost.",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "SUPER BIRD",
      logo: top5,
      players: "SINGLE PLAYERS",
      genrer: "PLATFORMER,ENDLESS RUNNER,",
      plaing_time: "3 MIN",
      gameDescription:
        "Stack Blocks of ice beneath Pengu the Penguin and help it get across and get home safely avoiding perils and obstacles that may come in its way.",
      screenShort: [
        { sort: sb1, title: "Select Super Bird game from Tournament list" },
        { sort: sb2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: sb3, title: "Choose account option to pay the entry fee" },
        { sort: sb4, title: "Enjoy the game" },
      ],
      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Super Bird, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts.",
        },
        {
          desc: "Score as much points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
      ],

      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "Try to collect as many frozen fishes in your run to get the most amount of points in addition to the distance travelled in the game.1 Frozen Fish = 1 Points",
        },
        {
          parahed: "heading2",
          paradesc:
            "Focus on upcoming obstacles rather than focusing on the character itself, if you know what’s to come next you can plan your jumps easily.",
        },
      ],

      control: "Mobile Controls",
      control1: "Tap to stack Ice blocks.",

      controlpoint1:
        "Points are awarded periodically, based on the amount of distance travelled by Pengu the Penguin within the game.",

      bonuspoints: [
        {
          bonus:
            "Collect frozen fish along the way to gain additional points, collecting frozen fishes grant additional 1 point for every fish collected to the overall score. This can greatly impact the end score.  ",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "TAP TAP SHOT",
      logo: top6,
    },
    {
      gametopic: woban,
      gamehed: "WORD SEARCH",
      logo: top7,
      players: "SINGLE PLAYERS",
      genrer: "WORD SEARCH, ABSTRACT, CASUAL",
      plaing_time: "3 MIN",
      gameDescription:
        "Roger the Rabbit archeologist is on a mission to uncover and learn the language of the people, help him find and read the inscriptions hidden in the stones left behind by the civilization.Sharpen your English Vocabulary in this relaxing word search game. ",

      screenShort: [
        { sort: w1, title: "Select Word Search game from Tournament list" },
        { sort: w2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: w3, title: "Choose account option to pay the entry fee" },
        { sort: w4, title: "Enjoy the game" },
      ],
      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Word Search, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts. ",
        },
        {
          desc: "Score as much points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
      ],

      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "Try improving your vocabulary by learning a new word every day from the dictionary",
        },
        {
          parahed: "heading2",
          paradesc:
            "Brush up on phonetics and learn up what alphabets come after another more frequently to form words. This can help you identify words more quickly.",
        },
      ],

      control: "Mobile Controls",
      control1: "Hold and drag on the Tiles to form a word",

      controlpoint1:
        "Search all the words shown on the table to collect gold coins and progress to the next level, Gold coins collected count towards the ingame score.",

      bonuspoints: [
        {
          bonus:
            "Each letter in the alphabet grants the player 10 Gold Coins if the same is used to form a word.  For Example if the word to be formed is  “DOG”  then  each alphabet in the word grants player 10 Gold Coins each for a total of 30 Gold Coins. Same for  “APEX” where the total gold coins awarded would be  40 as the word is 4 characters long. ",
        },
        {
          bonus:
            "Bonus Points are also awarded in a ratio for the amount of time left, essentially if a game is finished quicker the remaining time is converted into points.",
        },
        {
          bonus:
            "Bonus Points are also awarded for each Hint not used, essentially if 3 hints are remaining out of a total of 5 hints in the game, each remaining powerup grants an additional point for not utilizing it.",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "ROLLING CART",
      logo: top8,
      players: "SINGLE PLAYERS",
      genrer: "PLATFORMER, PHYSICS BASED",
      plaing_time: "5 MIN",
      gameDescription:
        "Help this sly fox escape with an ostrich egg as he carries it on his cart, keep in mind the egg is fragile and the roads equally bumpy. Keep the egg safe and try to escape the ostrich as it chases you.",

      screenShort: [
        { sort: r1, title: "Select Rolling Cart game from Tournament list" },
        { sort: r2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: r3, title: "Choose account option to pay the entry fee" },
        { sort: r4, title: "Enjoy the game" },
      ],
      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Rolling Cart, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts.",
        },
        {
          desc: "Score as much points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
        {
          desc: "Control the cart with a very wobbly egg on it and try to run away from the ostrich you stole it from.",
        },
        {
          desc: "Try to get as far as possible from the ostrich to register the highest possible score.",
        },
      ],

      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "Stay Focused and try not to hurry, the egg is physics based which means the faster you move over the bumpy terrain, the higher is the chances of the egg falling over. ",
        },
        {
          parahed: "heading2",
          paradesc:
            "Try to collect 10 stars and use the ability button to freeze the egg in the cart, doing this you can speed run through the level for 8 secs after which the egg returns to being wobbly.",
        },
      ],

      control: "Mobile Controls",
      control1: "Click on Right arrow button on screen to move forward",
      control2: "Click on the Left arrow button to come to a halt",

      controlpoint1:
        "Distance travelled is also calculated as a scoring factor, try to travel the most amount of distance.",

      bonuspoints: [
        {
          bonus:
            "In the game you will come across stars, collecting 10 stars will allow you the ability to freeze the position of the egg and speed run through the level for a total of 8 seconds.Stars can be collected throughout the run and the ability can be activated multiple times, however dropping the egg will end the game.",
        },
        {
          bonus:
            "Bonus Points are also awarded in a ratio for the amount of time left, essentially if a game is finished quicker the remaining time is converted into points.",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "FRUIT CHOP",
      logo: top9,
    },
    {
      gametopic: woban,
      gamehed: "CONNECT THE BALL",
      logo: top10,
      players: "SINGLE PLAYERS",
      genrer: "HYPER-CASUAL",
      plaing_time: "3 MIN",
      gameDescription:
        "Test your reflexes in this fast paced hypercasual game where you aim to get the orb just right inside the circle.",
      screenShort: [
        {
          sort: c1,
          title: "Select connect the ball game from Tournament list",
        },
        { sort: c2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: c3, title: "Choose account option to pay the entry fee" },
        { sort: c4, title: "Enjoy the game" },
      ],
      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Connect the Ball, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts. ",
        },
        {
          desc: "Score as many points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
      ],
      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "The game requires extreme levels of focus, try not to strain your eyes. Looking at your target before you tap is essential to the game.",
        },
        {
          parahed: "heading2",
          paradesc:
            "Strategize your play, switch between landing on the colored circles for additional points or regular circles for regular points.",
        },
        {
          parahed: "heading3",
          paradesc:
            "Take your time, the more you hurry in this game, the harder it becomes.",
        },
        {
          parahed: "heading4",
          paradesc:
            "Take regular breaks from the game, it is easy to get addicted to a game, looking continuously at the screen can harm the eyes, it is essential that you take breaks regularly.",
        },
      ],

      control: "Mobile Controls",
      control1:
        "Tap the Screen right when the green orb is inside the circle to move forward.",
      controlbuilding:
        "Click on the empty Island Grid/Tiles to place the Current Building on the Map.The active building that can be placed is displayed at the bottom of the screen with an animation and bubbles, This is the building that will be placed on the map/island when clicked on the grid.",
      controlpoint1:
        "Everytime the orb lands successfully inside the circle, a point is granted towards the ingame score.",
      lives: "Lives:",
      livestext:
        "The Player gets 3 Lives meaning the player can fail a total of 3 times before the game ends.",
      bonuspoints: [
        {
          bonus:
            "The Game will occasionally present colored circles of smaller sizes beside the bigger circles, Players can optionally earn more points in the game if they are able to place the orb in the colored circles. ",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "MATCH IT RIGHT",
      logo: top11,
    },
    {
      gametopic: woban,
      gamehed: "CONNECT THE WORD",
      logo: top12,
    },
    {
      gametopic: woban,
      gamehed: "SOLITAIRE KING",
      logo: top13,
    },
    {
      gametopic: woban,
      gamehed: "MERGE CITY",
      logo: top14,
      players: "SINGLE PLAYERS",
      genrer: "PUZZLE, HYPER-CASUAL",
      plaing_time: "3 MIN",
      gameDescription:
        "Merge City is a captivating puzzle and strategy game set on a vibrant floating island. Players merge similar buildings to create higher-level structures, earning coins/ points. The game features a colorful, cartoonish design and an intuitive grid layout where players can click on the island to drop the current building. With a Points System, time-based challenges, and Powerups, Merge City offers an engaging and rewarding gameplay experience. Players aim to build the most advanced city, achieve the highest score by accumulating the most coins while enjoying the visually appealing and interactive environment.",
      screenShort: [
        { sort: w1, title: "Select Word Search game from Tournament list" },
        { sort: w2, title: "Choose Play Free Option OR Join Tournament" },
        { sort: w3, title: "Choose account option to pay the entry fee" },
        { sort: w4, title: "Enjoy the game" },
      ],

      screenShort_des: [
        { desc: "Download the Just Game App and complete your registration." },
        {
          desc: "Search and Click on Merge City, and choose whether you wish to play a free game or compete against players.",
        },
        {
          desc: "If you choose to compete on the Leaderboards, select the desired amount you wish to play for. Prize amount won is dependent on the amount you play for, Higher Entry Fee allows for you to win higher amounts. ",
        },
        {
          desc: "Score as much points in the game before the in-game life is exhausted, Most games only have 1 life so make your moves cautiously.",
        },
        {
          desc: "Keep replaying to achieve the highest score and register it on the leaderboards. Only the Highest score is registered regardless of the number of times played.",
        },
      ],
      tricks: [
        {
          parahed: "heading1",
          paradesc:
            "Strategize your play technique, holding up buildings and not matching them right away will grant additional points if you match more quantities of buildings together.",
        },
        {
          parahed: "heading2",
          paradesc:
            "The Builds merge towards the tile where the last building was placed, the placement of the last building that leads up to the merge is essential to the game, so make sure to place the building in a position that helps free up tiles for further buildings to merge.",
        },
        {
          parahed: "heading3",
          paradesc:
            "Learn the Merging patterns, As you keep playing the game remember the merging pattern meaning after the merge what the kind of building it will become, this will help you manage the tile space more efficiently.",
        },
      ],

      control: "Mobile Controls",
      controlbuilding:
        "Click on the empty Island Grid/Tiles to place the Current Building on the Map.The active building that can be placed is displayed at the bottom of the screen with an animation and bubbles, This is the building that will be placed on the map/island when clicked on the grid.",
      controlpoint1:
        "3 or more of the Same Buildings can be combined together to form an upgraded building.",
      controlpoint2:
        "Game is Concluded when all the Grids/Tiles on the island are occupied and no further combination can be made or all tiles on the island and their respective buildings have been upgraded to the maximum upgrade available.",
      controlpoint3:
        "Each type of building has a point associated to it, the total points earned varies as per the type of building being merged. As buildings are being merged, place them strategically so you can benefit from merging a higher level as you play the game.",
      bonuspoints: [
        {
          bonus:
            "Gain additional points when you match 4 or more buildings together, the higher the number of buildings matched, the higher the points that is awarded to the player.",
        },
        {
          bonus:
            "Bonus Points are also awarded in a ratio for the amount of time left, essentially if a game is finished quicker the remaining time is converted into points.",
        },
        {
          bonus:
            "Bonus Points are also awarded for each token that is not used in the game from the building purchase menu, each remaining Token grants 25 additional point for not utilizing it.",
        },
      ],
    },
    {
      gametopic: woban,
      gamehed: "POKA",
      logo: top15,
    },
  ];
  const [isToggle, setIsToggle] = useState(false);
  return (
    <>
      <header>
        <div className="container">
          <div className="row">
            <div className="col-12 d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap flex-sm-nowrap">
              <Link to="/" onClick={() => window.scrollTo(0, 0)}>
                <img src={JustGameLogo} alt="Just  Game" width="180" />
              </Link>
              <nav className="main-menu">
                <ul>
                  <li className="game_all_list">
                    <a href="#how-it-works">
                      Our Games <i class="fa-solid fa-angle-down"></i>
                    </a>
                    <div className="game_menu">
                      {GameData.map((item, key) => {
                        return (
                          <ul>
                            <li

                            // onClick={() => {
                            //   navigate(`/${item?.Game_Name}`, {

                            //     // state: {
                            //     //   data: item,
                            //     // },
                            //   });
                            // }}
                            >
                              <Link to={`/${item?.Game_Name}`}>
                                {item?.Game_Name.replace(/-/g, " ")}
                              </Link>
                            </li>
                          </ul>
                        );
                      })}
                    </div>
                  </li>
                  <li>
                    <a href="#about">About Us</a>
                  </li>
                  <li>
                    <Link to="/support">Support</Link>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </header>
      <section
        className="main_banner"
        style={{ backgroundImage: `url(${Banner})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="main_ban_lt">
                <img
                  className="w-100 animate__animated animate__fadeInLeft"
                  src={Banner1}
                  alt=""
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="main_ban_rt home_banner_cash">
                <img
                  className="ob2_sec wow animate__animated animate__fadeInTopLeft"
                  src={Object2}
                  alt=""
                />
                <img
                  className="ob3_sec wow animate__animated animate__fadeInTopRight"
                  style={{ animationDelay: ".85s" }}
                  src={Object3}
                  alt=""
                />
                {/* <h6 className="wow animate__animated animate__shakeX">PLAY, WIN & SHOP</h6> */}
                <h2 className="wow animate__animated animate__fadeInRight">
                  PLAY & WIN
                </h2>
                <div
                  className="lakh_sec wow animate__animated animate__zoomIn"
                  style={{ animationDelay: ".5s" }}
                >
                  <img className="ob1_sec" src={Object1} alt="" />
                  <img src={cash} alt="" />
                </div>
                {/* <div
                  className="shopping_sec wow animate__animated animate__fadeInUp"
                  style={{ animationDelay: ".95s" }}
                >
                  <img src={Voucher} alt="" />
                </div> */}
                <img
                  className="ob4_sec wow animate__animated animate__fadeInBottomLeft"
                  style={{ animationDelay: "1.15s" }}
                  src={Object4}
                  alt=""
                />
                <img
                  className="ob5_sec wow animate__animated animate__fadeInBottomRight"
                  style={{ animationDelay: ".95s" }}
                  src={Object5}
                  alt=""
                />
                <a
                  href={download_now}
                  // download={"justgame.apk"}
                >
                  <img
                    onClick={handleClickScroll}
                    className="ob6_sec  wow animate__animated animate__zoomIn"
                    style={{ animationDelay: ".95s", cursor: "pointer" }}
                    src={Object6}
                    alt=""
                  />
                </a>
                {/* <div
                  className="play_store_sec wow animate__animated animate__zoomIn"
                  style={{ animationDelay: "1.55s" }}
                >
                  <img src={GooglePlay} alt="" />
                  <img src={AppStore} alt="" />
                </div> */}
                <img className="ban_crs" src={Bancross} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <section className="partner_sec">
        <div className="container">
          <div className="row">
            
            <div className="col-sm-3"></div>
            <div className="col-sm-6">
              <div className="partner_icon">
                <div>
                  <img draggable="false" src={Gpay} alt="" />
                </div>
                <div>
                  <img draggable="false" src={PhonePay} alt="" />
                </div>
                <div>
                  <img draggable="false" src={Amazon} alt="" />
                </div>
                 <div>
                  <img draggable="false" src={paytm} alt="" />
                </div> 
              </div>
              <p className="partner_txt">WITHDRAWAL PARTNERS</p>
            </div>
            <div className="col-sm-3"></div>
          </div>
        </div>
      </section> */}

      {/* <section className="how-it-works" >
        <div className="container-fluid p-0">
          <div className="row g-0">
            <div className="col-12 position-relative">
              <h2 className="inner-title text-center">TURN YOUR WINS INTO REAL REWARDS</h2>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <p className="text-yellow mb-3 sub-heading">PLAY FOR FREE</p>
              <div className="yellow-gradient-box d-flex align-items-center">
                <div>
                  <p className="small-txt m-0">PLAY GAMES & WIN</p>
                  <p className="m-0 lt_txt">JUST GAME COINS</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-12 col-lg-6">
              <p className="text-yellow mb-3 sub-heading">GET SHOPPING VOUCHERS</p>
              <div className="violet-gradient-box d-flex align-items-center">
                <div>
                  <p className="small-txt m-0">GET GIFT VOUCHERS</p>
                  <p className="m-0 lt_txt">OVER 300+ PRODUCTS</p>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5 pt-3 pb-5">
              <p className="text-center inner-text">
                Just Game rewards your gaming skills with Just game coins, which can be redeemed into real gift voucher. <br />Each earned coin brings you closer to transforming your gaming passion into tangible real-world rewards.
              </p>
            </div>
          </div>
        </div>
      </section> */}

      {/* <section className="game_video_sec" id="how-it-works">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <div className="game_video_lt wow animate__animated animate__fadeInLeft">
                <h5>ALL IN ONE GAMING PLATFORM</h5>
                <h6>VARIETY OF GAMES</h6>
                <p>
                  Just Game serves as your comprehensive gaming platform,
                  offering a diverse collection of games across various
                  categories.{" "}
                </p>
                <p>
                  Dive into exciting adventures, tackle intricate puzzles, and
                  put your strategic brilliance to the test. The best part? It's
                  all free.
                </p>
              </div>
            </div>
            <div className="col-sm-6">
              <div className="game_video_rt">
                {/* <VideoBox/>  *}

                {arrImg &&
                  arrImg.map((FileDetails, index) => {
                    return index == gameSlider ? (
                      <Player
                        preload="auto"
                        aspectRatio={"16:9"}
                        autoPlay={true}
                        fluid={true}
                        playsInline={true}
                      >
                        <source src={FileDetails.video} />
                      </Player>
                    ) : (
                      ""
                    );
                  })}
              </div>
            </div>
            <div className="col-sm-12">
              <div className="thumbnail_slider">
                {/* <CustomArrows/> */}

      {/* <button Scan the code or <br /> 
                onClick={(e) =>{ e.preventDefault();console.log('ok'); sliderRef.slickNext()}}
                ><i class="fa-solid fa-chevron-right"   ></i>
                </button>
                <button 
                    onClick={(e) =>{ e.preventDefault(); sliderRef.slickPrev()}}
                ><i class="fa-solid fa-chevron-left"   ></i>
                </button> *}
                <Slider
                  {...settings}
                  ref={(slider) => {
                    sliderRef = slider;
                  }}
                >
                  <div>
                    <img
                      draggable="false"
                      src={GAMES2}
                      alt=""
                      onClick={() => changeSlide(0)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES1}
                      alt=""
                      onClick={() => changeSlide(1)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES3}
                      alt=""
                      onClick={() => changeSlide(2)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES4}
                      alt=""
                      onClick={() => changeSlide(3)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES5}
                      alt=""
                      onClick={() => changeSlide(4)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES6}
                      alt=""
                      onClick={() => changeSlide(5)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES7}
                      alt=""
                      onClick={() => changeSlide(6)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES8}
                      alt=""
                      onClick={() => changeSlide(7)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES9}
                      alt=""
                      onClick={() => changeSlide(8)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES10}
                      alt=""
                      onClick={() => changeSlide(9)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES11}
                      alt=""
                      onClick={() => changeSlide(10)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES12}
                      alt=""
                      onClick={() => changeSlide(11)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES13}
                      alt=""
                      onClick={() => changeSlide(12)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES14}
                      alt=""
                      onClick={() => changeSlide(13)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES15}
                      alt=""
                      onClick={() => changeSlide(14)}
                    />
                  </div>
                  <div>
                    <img
                      draggable="false"
                      src={GAMES16}
                      alt=""
                      onClick={() => changeSlide(15)}
                    />
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section> */}

      <section className="redem_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-3 col-6">
              <div className="redem1">
                <div className="redem_up">
                  <img draggable="false" src={Support} alt="" />
                </div>
                <div className="redem_dwn">
                  <h4>24X7</h4>
                  <p>SUPPORT</p>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className="redem1">
                <div className="redem_up">
                  <img draggable="false" src={Shield} alt="" />
                </div>
                <div className="redem_dwn">
                  <h4>100%</h4>
                  <p>SECURE & LEGAL</p>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className="redem1">
                <div className="redem_up">
                  <img draggable="false" src={RedemVoucher} alt="" />
                </div>
                <div className="redem_dwn">
                  <h4>INSTANT</h4>
                  <p>WITHDRAWALS</p>
                </div>
              </div>
            </div>
            <div className="col-sm-3 col-6">
              <div className="redem1">
                <div className="redem_up">
                  <img draggable="false" src={People} alt="" />
                </div>
                <div className="redem_dwn">
                  <h4>REAL PLAYERS</h4>
                  <p>REAL ADVENTURE</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="mt-2">
        <div className="container">
          {/* <div className="row">
          <div className="col-12">
            <h2 className="inner-title text-center">GAMES</h2>
            <div className="game-slider">
              <div><img src={GAMES1} alt="" /></div>
              <div><img src={GAMES2} alt="" /></div>
              <div><img src={GAMES3} alt="" /></div>
              <div><img src={GAMES1} alt="" /></div>
              <div><img src={GAMES2} alt="" /></div>
              <div><img src={GAMES3} alt="" /></div>
            </div>
          </div>
        </div> */}

          {/* <Slider {...settings}>
            <div>
              <img src={GAMES1} alt="" />
            </div>
            <div>
              <img src={GAMES2} alt="" />
            </div>
            <div>
              <img src={GAMES3} alt="" />
            </div>
            <div>
              <img src={GAMES1} alt="" />
            </div>
            <div>
              <img src={GAMES2} alt="" />
            </div>
            <div>
              <img src={GAMES3} alt="" />
            </div>

          </Slider> */}
        </div>
      </section>

      <section className="top_skill_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h6>TOP SKILL BASED GAMES</h6>
              <div className="top_games_list">
                {GameData.map((item, key) => {
                  // console.log(item);
                  return (
                    <>
                      <div
                        onClick={() =>
                          navigate(`/${item?.Game_Name}`, {
                            state: {
                              data: item,
                            },
                          })
                        }
                        className="top_games_inn"
                        style={{
                          cursor: "pointer",
                          textDecoration: "none",
                        }}
                      >
                        <>
                          <img
                            src={require(`./assets/images/${item?.image_url}`)}
                            alt="Game Logo"
                          />
                          <p>{item?.Game_Name.replace(/-/g, " ")}</p>
                        </>
                      </div>
                    </>
                  );
                })}
                {/* {bannerdata.map((item, key) => (
                   <div className="top_games_inn">
                     {item.gameDescription ? (
                       <Link to="innerpage" state={item}>
                         <img src={item.logo} alt="Game Logo" />
                         <p>{item.gamehed}</p>
                       </Link>
                     ) : (
                       <Link state={item}>
                         <img src={item.logo} alt="Game Logo" />
                         <p>{item.gamehed}</p>
                       </Link>
                     )} 
                   </div>
                 ))} */}
              </div>
              {/* <div className="top_games_list">
              <div className="top_games_inn">
                <Link to={'innerpage'}><img src={top1} alt=""/></Link>
                <p>HIT IT RIGHT</p>
              </div>
              <div className="top_games_inn">
                <img src={top2} alt=""/>
                <p>COPTER RIDE</p>
              </div>
              <div className="top_games_inn">
                <img src={top3} alt=""/>
                <p>JUMP IT RIGHT</p>
              </div>
              <div className="top_games_inn">
                <img src={top4} alt=""/>
                <p>SUPER MONKEY</p>
              </div>
              <div className="top_games_inn">
                <img src={top5} alt=""/>
                <p>SUPER BIRD</p>
              </div>
              <div className="top_games_inn">
                <img src={top6} alt=""/>
                <p>TAP TAP SHOT</p>
              </div>
              <div className="top_games_inn">
                <img src={top7} alt=""/>
                <p>WORD SEARCH</p>
              </div>
              <div className="top_games_inn">
                <img src={top8} alt=""/>
                <p>ROLLING CART</p>
              </div>
              <div className="top_games_inn">
                <img src={top9} alt=""/>
                <p>FRUIT CHOP</p>
              </div>
              <div className="top_games_inn">
                <img src={top10} alt=""/>
                <p>CONNECT THE BALL</p>
              </div>
              <div className="top_games_inn">
                <img src={top11} alt=""/>
                <p>MATCH IT RIGHT</p>
              </div>
              <div className="top_games_inn">
                <img src={top12} alt=""/>
                <p>CONNECT THE WORD</p>
              </div>
              <div className="top_games_inn">
                <img src={top13} alt=""/>
                <p>SOLITAIRE KING</p>
              </div>
              <div className="top_games_inn">
                <img src={top14} alt=""/>
                <p>MERGE CITY</p>
              </div>
              <div className="top_games_inn">
                <img src={top15} alt=""/>
                <p>POKA</p>
              </div>
            </div> */}
            </div>
          </div>
        </div>
      </section>

      <section className="top_game_about_sec" id="about">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h6>ABOUT US</h6>
              <div className="top_game_about_sec_inn">
                <p>
                  Just Game, operated by Thinktrek Entertainment Private
                  Limited, provides skill-based games and formats with a strong
                  emphasis on fairness and safety. Thinktrek Entertainment
                  Private Limited ensures the integrity of all games available
                  on Just Game. All transactions are secure and protected, with
                  advanced fraud detection mechanisms in place to prevent any
                  fraudulent activity.
                </p>
                <p>
                  The Just Game app is currently available for Android users and
                  can be downloaded via the Download button on this page.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="customer_rating_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h6>CUSTOMER REVIEWS</h6>

              <div className="rating_main">
                <div className="rating_lt">
                  <h1>4.5</h1>
                  <span className="rate_star">
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star"></i>
                    <i class="fa-solid fa-star-half-stroke"></i>
                  </span>
                </div>
                <div className="rating_rt">
                  {/* {_dataProgressBar.map((item,index)=>{
                    return(

                  <div><span>{item.label}</span><ProgressLine
                  
                  backgroundColor="lightblue"
                  visualParts={[
                    {
                      percentage: item.value,
                      color: "indianred"
                    }
                  ]}
                /></div>                      
                    )
                  })} */}

                  <div>
                    <span>5</span>
                    <ProgressBar now={80} />
                  </div>
                  <div>
                    <span>4</span>
                    <ProgressBar now={60} />
                  </div>
                  <div>
                    <span>3</span>
                    <ProgressBar now={40} />
                  </div>
                  <div>
                    <span>2</span>
                    <ProgressBar now={25} />
                  </div>
                  <div>
                    <span>1</span>
                    <ProgressBar now={10} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="customer_review">
        <div className="container">
          <div className="row">
            <div className="customer_review_inn">
              <Slider {...review}>
                <div className="slider_con">
                  <div className="slider_con_up">
                    <img src={Client} alt="" />
                  </div>
                  <div className="slider_con_dwn">
                    <h6>SUJIT KADAM</h6>
                    <span className="client_rate_star">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star" style={{ color: "#fff" }}></i>
                    </span>
                  </div>
                  <p className="client_feed">
                    I've been playing Just Game for months now. The gameplay is
                    smooth, the graphics are stunning, and the best part? You
                    can actually win real money! The thrill of competing against
                    other players and earning rewards is unbeatable.
                  </p>
                </div>

                <div className="slider_con">
                  <div className="slider_con_up">
                    <img src={Client1} alt="" />
                  </div>
                  <div className="slider_con_dwn">
                    <h6>SAVITA SAH</h6>
                    <span className="client_rate_star">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star" style={{ color: "#fff" }}></i>
                    </span>
                  </div>
                  <p className="client_feed">
                    As someone who's tried numerous real money games, I can
                    confidently say that Just Game stands out from the crowd.
                    The game mechanics are fair, the tournaments are exciting,
                    and the cash prizes are legit. What I appreciate most is the
                    transparency and security measures in place to ensure a fair
                    gaming environment.
                  </p>
                </div>

                <div className="slider_con">
                  <div className="slider_con_up">
                    <img src={Client2} alt="" />
                  </div>
                  <div className="slider_con_dwn">
                    <h6>MAMATA SAHOO</h6>
                    <span className="client_rate_star">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star" style={{ color: "#fff" }}></i>
                    </span>
                  </div>
                  <p className="client_feed">
                    I stumbled upon Just Game a few weeks ago, and it has
                    completely changed the way I view mobile gaming. Not only is
                    it incredibly entertaining, but it also offers a chance to
                    win real cash prizes. The level of skill and strategy
                    required adds an extra layer of excitement, making every win
                    feel truly rewarding.
                  </p>
                </div>

                <div className="slider_con">
                  <div className="slider_con_up">
                    <img src={Client1} alt="" />
                  </div>
                  <div className="slider_con_dwn">
                    <h6>SAVITA SAH</h6>
                    <span className="client_rate_star">
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star"></i>
                      <i class="fa-solid fa-star" style={{ color: "#fff" }}></i>
                    </span>
                  </div>
                  <p className="client_feed">
                    As someone who's tried numerous real money games, I can
                    confidently say that Just Game stands out from the crowd.
                    The game mechanics are fair, the tournaments are exciting,
                    and the cash prizes are legit. What I appreciate most is the
                    transparency and security measures in place to ensure a fair
                    gaming environment.
                  </p>
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </section>

      <section className="how_to_install_sec" id="how_to_install">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h6>HOW TO INSTALL</h6>
              <p className="how_to_install mb-4">
                FOLLOW THE STEPS BELOW TO START PLAYING JUST GAME ON YOUR
                ANDROID DEVICE NOW!
              </p>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3 h-100 justify-content-between border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    1
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      click on the
                      <span
                        className="text-start"
                        style={{ fontWeight: "bold" }}
                      >
                        "Download App"
                      </span>
                      button below.
                    </p>
                  </div>
                </div>
                <div className="text-center">
                  <a
                    href={download_now}
                    // download={"justgame.apk"}
                  >
                    <img src={Object6} className="w-50 mb-3" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3  border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    2
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      On your phone <br />
                      click{" "}
                      <span
                        className="text-center text-md-start"
                        style={{ color: "#340764", fontWeight: "bold" }}
                      >
                        "Download Anyway"
                      </span>{" "}
                      to start downloading.
                    </p>
                  </div>
                </div>
                <div className="text-md-start text-center ps-0 ps-md-5 ms-0 ms-md-5 mt-3">
                  <img src={Object7} className="w-50" />
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3 h-100 border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    3
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p mb-3"
                      style={{ color: "#340764" }}
                    >
                      Once it's downloaded, Install and Open the app, and sign
                      up.
                    </p>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      Tap Continue to allow customization for better experience.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 p-2">
              <div className="card p-3 h-100 border-0">
                <div className="d-flex align-items-md-start flex-column flex-md-row align-items-center">
                  <div className="me-3 text-white numberBox d-flex align-items-center justify-content-center">
                    4
                  </div>
                  <div>
                    <p
                      className="text-center text-md-start how_to_install_p"
                      style={{ color: "#340764" }}
                    >
                      Enter your phone number, verify with OTP,
                      <br /> and use any referral code to get extra rewards.
                      <br />
                      <span style={{ color: "#340764", fontWeight: "bold" }}>
                        START PLAYING!
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="faq_sec" id="games">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h6>FAQS</h6>
              {/* <Accordian title="1. How does the JustGame work?" content="JustGame provides a platform for users to play a variety of free games and earn points. These points can be redeemed for shopping vouchers."  />
        <Accordian
          title="2. Is it really free to play games on the app?"
          content="Second Accordion content"
          
        />
        <Accordian
          title="3. How do I earn points while playing games?"
          content="Third Accordion content"
          
        />
        <Accordian
          title="4. What kind of games are available on the app?"
          content="Fourth Accordion content"
          
        />
        <Accordian
          title="5. Can I play the games on different devices?"
          content="Five Accordion content"
          
        />
        <Accordian
          title="6. How do I redeem my points for shopping vouchers?"
          content="Six Accordion content"
          
        /> */}

              <Accordion defaultActiveKey="0">
                {arrayData?.slice(0, next)?.map((item, key) => {
                  return (
                    <Accordion.Item eventKey={key}>
                      <Accordion.Header>{item.headerName}</Accordion.Header>
                      <Accordion.Body>{item.bodyDetails}</Accordion.Body>
                    </Accordion.Item>
                  );
                })}
                {/* <Accordion.Item eventKey="0">
                  <Accordion.Header>1. How does the JustGame work?</Accordion.Header>
                  <Accordion.Body>
                    JustGame provides a platform for users to play a variety of free games and earn points. These points can be redeemed for shopping vouchers.
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>2. Is it really free to play games on the app?</Accordion.Header>
                  <Accordion.Body>
                    Yes, all the games on our app are completely free to play. You can enjoy gaming without any upfront costs.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="2">
                  <Accordion.Header>3. How do I earn points while playing games?</Accordion.Header>
                  <Accordion.Body>
                    You earn points by achieving high scores and completing in-game challenges via playing different battle, league & tournament. The more you play and excel, the more points you accumulate.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="3">
                  <Accordion.Header>4. What kind of games are available on the app?</Accordion.Header>
                  <Accordion.Body>
                    We offer a diverse range of games, including puzzles, running, action,platform,strategy, and more. There's something for every gaming preference.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="4">
                  <Accordion.Header>5. Can I play the games on different devices?</Accordion.Header>
                  <Accordion.Body>
                    Yes, our app is designed to be compatible with various devices, including ios and android smartphones. You can seamlessly switch between devices.
                  </Accordion.Body>
                </Accordion.Item>

                <Accordion.Item eventKey="5">
                  <Accordion.Header>6. How do I redeem my points for shopping vouchers?</Accordion.Header>
                  <Accordion.Body>
                    Once you've accumulated enough points, you can visit the app's rewards section. There, you'll find options to redeem your points for a variety of shopping vouchers from popular retailers.
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>

              {next < arrayData?.length && (
                <div className="load_more" onClick={handleMoreImage}>
                  <a href="">LOAD MORE</a>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* <section className="how-to-win mb-5 pb-5" id="how-to-win">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="inner-title text-center">HOW TO WIN</h2>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center">
              <img src={Battle} alt="" />
              <h3>BATTLE</h3>
              <p>Player vs Player game & Highest scorer win.</p>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center">
              <img src={PlayWithFriends} alt="" />
              <h3>PLAY WITH FRIENDS</h3>
              <p>Create your own room and play privately with your friend.</p>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center">
              <img src={Ranking} alt="" />
              <h3>LEAGUE</h3>
              <p>Board range of top scorer over the leaderboard win the match.</p>
            </div>
            <div className="col-6 col-sm-6 col-md-3 col-lg-3 text-center">
              <img src={TOURNAMENT} alt="" />
              <h3>TOURNAMENT</h3>
              <p>32 players & 8 rounds to be the champion.</p>
            </div>
          </div>
        </div>
      </section> */}
      <Footer />
      {/* <Footer1 /> */}
    </>
  );
};

export default JustGameWeb;
