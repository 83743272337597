import React, { useEffect } from 'react'
import JustGameLogo from '../components/assets/images/just-game-logo.png'
import GooglePlay from '../components/assets/images/google-play.png'
import AppStore from '../components/assets/images/app-store.png'
import Thinktrek from '../components/assets/images/thinktrek.png'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import Header from './Header'
const Responsible = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
   <Header />
      <section className="privacy_sec">
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">Registration and KYC Process for JustGame</h2>
              

              <h4>Step 1: Sign-Up</h4>
              <ul>
                <li><strong>Phone Number:</strong> Enter your phone number.</li>
                <li><strong>OTP Verification:</strong> A One-Time Password (OTP) will be sent to the provided phone number for verification. Enter the OTP to complete the initial registration.</li>
                <li><strong>Terms & Conditions Checkbox:</strong>
                  <ul>
                    <li>Checkbox 1: "I accept the JustGame Terms & Conditions and Privacy Policy" (Mandatory).</li>
                    <li>Checkbox 2: "Notify me about ongoing offers and promotions via WhatsApp/SMS" (Optional).</li>
                    <li>Age verification of user </li>
                  </ul>

                </li>
              </ul>

              <h4>Step 2: Adding Funds to Wallet</h4>
              <ul>
                <li>
                  <strong>Payment Options:</strong>
                  <ul>
                    <li>Select from the available payment methods such as debit/credit cards, UPI, net banking, etc.</li>
                    <li>Disclaimer: <i>"Card/Bank details will be handled and processed by a third-party payment gateway. Please review the third party's privacy policies on how your data will be processed."</i></li>
                  </ul>
                </li>
              </ul>

              <h4>Step 3: Complete KYC (Optional at Registration, Mandatory for Withdrawals)</h4>
              <ul>
                <li>User Verification Methods (Choose any one):
                  <ul>
                    <li>Aadhaar-Based Verification:
                      <ul>
                        <li>Enter your Aadhaar number and verify using OTP sent to your linked mobile number.</li>
                        <li><em>Aadhaar card upload:</em> Upload a scanned copy of your Aadhaar card.</li>
                      </ul>
                    </li>
                    <li>Driving License Verification:
                      <ul>
                        <li>Enter your driving license ID and Date of Birth.
                        </li>
                        <li>Upload both the front and back sides of your driving license.</li>
                      </ul>
                    </li>
                    <li>Voter ID Verification:
                      <ul>
                        <li>Enter your Voter ID number and select your state.
                        </li>
                        <li>Upload both the front and back sides of your Voter ID card.
                        </li>
                      </ul>
                    </li>
                    <li>After uploading, verify the details for accuracy before confirming.</li>
                  </ul>
                </li>
              </ul>
              <h4>Disclaimer:</h4>
              <ul>
                <li>Your Aadhaar, driving license, and voter ID details will be used solely for identity verification as part of the Know Your Customer (KYC) process, in compliance with applicable laws. All data will be securely stored and processed in accordance with our Privacy Policy.
                </li>
                <li>
                Providing fake or any other person’s documents may lead to permanent account blocking. 
                </li>
              </ul>
            </div>
          </div>
        </div>

       
      </section>

      <Footer />
    </>
  )
}

export default Responsible
