import React, { useEffect } from 'react'
import JustGameLogo from '../components/assets/images/just-game-logo.png'
import GooglePlay from '../components/assets/images/google-play.png'
import AppStore from '../components/assets/images/app-store.png'
import Thinktrek from '../components/assets/images/thinktrek.png'
import Footer from './Footer'
import { Link } from 'react-router-dom'
import Header from './Header'
const Privacy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
    <Header />
      <section className="privacy_sec">
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">PRIVACY POLICY</h2>
              
              <p>
                JustGame is committed to ensuring the highest standards of compliance with laws and regulations governing the prevention of money laundering and the combating of the financing of terrorism, particularly under the <strong>Prevention of Money Laundering Act, 2002 (PMLA)</strong>. This policy outlines the measures and practices adopted by JustGame to ensure compliance with the PMLA and to prevent the misuse of its platform for money laundering activities.
              </p>

              <h4 className='mt-5'>1. Purpose and Scope</h4>
              <p>This policy is intended to outline the steps JustGame takes to comply with the PMLA, including:</p>
              <ul>
                <li className='mb-3'>Preventing and detecting any activity related to money laundering or terrorist financing.</li>
                <li className='mb-3'>Ensuring that users of the platform act in compliance with all applicable laws.</li>
                <li className='mb-3'>Protecting JustGame from any legal or reputational risks associated with non-compliance.</li>
              </ul>
              <p>This policy applies to all users, employees, and any third parties associated with JustGame.</p>


              <h4 className='mt-5'>2. Definitions</h4>
              <ul>
                <li className='mb-3'>
                  <strong>Money Laundering:</strong> As defined under the PMLA, money laundering involves any process where proceeds from criminal activities are disguised as legitimate income. This could include converting illegally obtained funds to legal assets.
                  </li>
                <li className='mb-3'>
                <strong>Suspicious Transaction:</strong> Any transaction that gives rise to suspicion that it may involve proceeds of crime, financing of terrorism, or any attempt to evade reporting requirements.
                </li>
                <li className='mb-3'>
                <strong>Know Your Customer (KYC):</strong> The process of verifying the identity and credentials of customers to ensure compliance with legal requirements and to prevent fraudulent activities.
                </li>
              </ul>

              <h4 className='mt-5'>3. Know Your Customer (KYC) Requirements</h4>
              <p>
                To comply with the PMLA, JustGame implements strict KYC procedures for all users registering on the platform. The following processes are part of the KYC requirements:
              </p>
              <ul>
                <li className='mb-3'>
                  <strong>User Identity Verification:</strong> All users must provide valid and verifiable identification documents at the time of registration. Accepted documents include:
                  <ul>
                    <li className='mb-3'>
                      Government-issued identity cards (Aadhaar, Driving License, PAN card, etc.).
                    </li>
                  </ul>
                </li>
                <li className='mb-3'>
                  <strong>Age Verification:</strong> JustGame only allows users 18 years of age or older to access pay-to-play on the platform. Users must provide accurate details, and JustGame may verify the information using identity proof.
                </li>
                <li className='mb-3'>
                  <strong>Account Monitoring:</strong> Periodic checks may be performed to ensure user information's continued accuracy and validity. As part of this process, users may be required to provide updated documentation.
                </li>
              </ul>

              <h4 className='mt-5'>4. Anti-Money Laundering (AML) Measures</h4>
              <p>
                JustGame has implemented the following Anti-Money Laundering (AML) measures to ensure compliance with the PMLA:
              </p>
              <ul>
                <li className='mb-3'>
                  <strong>Transaction Monitoring:</strong> All transactions on the JustGame platform are continuously monitored for suspicious activity. This includes large transactions, unusual spending patterns, and repeated requests for fund withdrawals.
                </li>
                <li className='mb-3'>
                <strong>Suspicious Transaction Reporting (STR):</strong> If any suspicious activity is detected, JustGame will report the transaction to the Financial Intelligence Unit - India (FIU-IND) in compliance with PMLA regulations. Suspicious activities may include, but are not limited to:
                  <ul>
                    <li className='mb-3'>Deposits or withdrawals of unusually large sums of money.</li>
                    <li className='mb-3'>Unexplained or irregular patterns of play.</li>
                    <li className='mb-3'>Transactions that appear to be structured to evade reporting thresholds.</li>
                  </ul>
                </li>
                <li className='mb-3'>
                <strong>Record-Keeping:</strong> JustGame will maintain records of all user transactions and identity verifications for a minimum of 5 years from the date of the transaction or cessation of the relationship with the user, as required under PMLA.
                </li>
              </ul>

              <h4 className='mt-5'>5. User Responsibilities</h4>
              <p>As part of JustGame's commitment to preventing money laundering, users are expected to:</p>
              <ul>
                <li className='mb-3'>Provide accurate and complete information: When creating an account, users must provide true and correct details, including identity and address proof.</li>
                <li className='mb-3'>Avoid structuring transactions: Users are prohibited from engaging in any activities designed to avoid detection, such as structuring transactions to avoid reporting thresholds.</li>
                <li className='mb-3'>Report suspicious activity: Users are encouraged to report any suspicious or unusual transactions or activities they encounter on the platform to JustGame's customer support team.</li>
              </ul>

              <h4 className='mt-5'>6. Prohibited Activities</h4>
              <p>The following activities are strictly prohibited on the JustGame platform:</p>
              <ul>
                <li className='mb-3'>Using funds that are derived from illegal activities.</li>
                <li className='mb-3'>Attempting to deposit or withdraw funds to/from multiple accounts to evade detection.</li>
                <li className='mb-3'>Participating in activities that may be related to money laundering or terrorist financing.</li>
              </ul>
              <p>
                JustGame reserves the right to suspend or terminate any account that violates this policy, and such actions may be reported to the relevant authorities.
              </p>

              <h4 className='mt-5'>7. Employee Training and Responsibilities</h4>
              <p>JustGame ensures that all employees, particularly those involved in transactions and customer service, know their responsibilities in detecting and reporting suspicious activity.
              </p>
              <ul>
                <li className='mb-3'>
                  <strong>Training:</strong> Staff receive regular training sessions on identifying suspicious transactions, record-keeping, and compliance with AML laws.
                </li>
                <li className='mb-3'>
                  <strong>Confidentiality:</strong> Employees are expected to maintain confidentiality regarding suspicious activity reporting (SAR) and other AML-related activities.
                </li>
              </ul>

              <h4 className='mt-5'>8. Cooperation with Law Enforcement</h4>
              <p>
                JustGame fully cooperates with law enforcement authorities, regulators, and government agencies to ensure the enforcement of anti-money laundering laws.
              </p>
              <ul>
                <li className='mb-3'>
                  <strong>Information Sharing:</strong> JustGame will provide information related to suspicious transactions, user identity, and transaction history to authorities, as required by law.
                </li>
                <li className='mb-3'>
                  <strong>Timely Reporting:</strong> As per the PMLA guidelines, all suspicious transactions will be promptly reported to the <strong>Financial Intelligence Unit (FIU).</strong>
                </li>
              </ul>

              <h4 className='mt-5'>9. Consequences of Non-Compliance</h4>
              <p>
              Non-compliance with this policy by users or employees will lead to immediate action, including:
              </p>
              <ul>
                <li className='mb-3'><strong>Account suspension or termination:</strong> Users found violating this policy will have their accounts suspended or permanently closed.</li>
                <li className='mb-3'><strong>Legal action:</strong> JustGame may pursue legal action, including reporting to law enforcement, against users involved in money laundering or suspicious activities.</li>
                <li className='mb-3'><strong>Internal Disciplinary Action:</strong> Employees failing to comply with the policy will face disciplinary action, which may include termination of employment.</li>
              </ul>

              <h4 className='mt-5'>10. Updates to this Policy</h4>
              <p>
                JustGame reserves the right to modify or update this policy occasionally to ensure compliance with legal and regulatory changes. Users will be notified of significant changes to the policy, and the updated policy will be available on the platform.
              </p>

              <h4 className='mt-5'>Contact Us</h4>
              <p>
                If you have any questions or concerns about this policy or its application, please contact us at <a href="mailto:support@justgame.com">support@justgame.com</a>.
              </p>
              <p>
                <strong>JustGame</strong> remains committed to complying with the <strong>Prevention of Money Laundering Act, 2002</strong>, and ensuring that our platform is not used illegally. By adhering to this policy, we aim to provide all our users a safe and transparent environment.
              </p>
          </div>
        </div>
      </div>


    </section >

      <Footer />
    </>
  )
}

export default Privacy
