import React, { useEffect } from 'react'
import JustGameLogo from '../components/assets/images/just-game-logo.png'
import GooglePlay from '../components/assets/images/google-play.png'
import AppStore from '../components/assets/images/app-store.png'
import Thinktrek from '../components/assets/images/thinktrek.png'
import Footer from './Footer'
import { Link } from 'react-router-dom'
const Responsible = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <header>
        <div class="container">
          <div class="row">
            <div
              class="col-12 d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap flex-sm-nowrap"
            >
              <Link to="/"
              ><img
                  src={JustGameLogo}
                  alt="Just  Game"
                  width="180"
                /></Link>
              <ul class="app-download">
                <li>
                  <a href=""
                  ><img src={GooglePlay} alt="Google Play"
                    /></a>
                </li>
                <li>
                  <a href=""
                  ><img src={AppStore} alt="App Store"
                    /></a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <section className="privacy_sec">
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">DISCLAIMER</h2>
              

              <p>
                Playing this game may be habit-forming or carry financial risks. Please play responsibly.
                JustGame is the owner of and retains all rights to the assets, content, services, information, games, and graphics on this website, except for any third-party content.
              </p>
              <p>
                JustGame does not make any representations or warranties regarding the accuracy, completeness, reliability, or adequacy of third-party content on this website. All content, materials, information, and games on this website, including text, graphics, and links, are provided "AS IS" without any warranties, whether express or implied.
                *JustGame pay-to-play is available only to individuals aged 18 and above. The platform is accessible in states where it is permitted by law. Therefore, users in certain states may be unable to access the app or its contests. 
              </p>
              <p>
                Participation in JustGame implies acceptance of the platform's and individual game's terms and conditions. Users are responsible for ensuring they comply with local regulations and laws related to online gaming and financial transactions. JustGame reserves the right to modify, suspend, or terminate any part of its services at its discretion and without prior notice.(Terms & Conditions applied).  The company does not assume any liability for user-generated content or interactions that may arise from the use of the platform. 
              </p>
            </div>
          </div>
        </div>

       
      </section>

      <Footer />
    </>
  )
}

export default Responsible
