import React from "react";
import JustGameLogo from "../components/assets/images/just-game-logo.png";
import GooglePlay from "../components/assets/images/google-play.png";
import AppStore from "../components/assets/images/app-store.png";
import Thinktrek from "../components/assets/images/thinktrek.png";
import { Link, NavLink, useNavigate } from "react-router-dom";
import Games from "../components/Games.json";
const Footer = ({ props }) => {
  const navigate = useNavigate();
  const handleFooterGameClick = (e, index) => {
    e.preventDefault();
    console.log("Footer Game CLicked", index);
  };

  const scrollToParent = () => {
    // Navigate to the parent route
    navigate("/");

    // Use a small delay to allow the navigation to happen before scrolling
    setTimeout(() => {
      // Find the parent section and scroll into view
      const parentSection = document.querySelector("#about");

      if (parentSection) {
        parentSection.scrollIntoView({ behavior: "smooth" });
      }
    }, 100);
  };

  return (
    <footer className="footer_sec">
      <div class="container">
        <div class="row">
          <div className="col-sm-12">
            <div className="text-center mb-5">
              <img src={JustGameLogo} alt="Just Game" width="150" />
            </div>
            <h6>GAMES</h6>
            <div>
              <div className="list-container" style={{ cursor: "pointer" }}>
                {Games?.map((game, index) => {
                  return (
                    <div
                      key={index}
                      className="list-item"
                      onClick={(e) => {
                        navigate(`/${game?.Game_Name}`, {
                          state: { data: game },
                        });
                      }}
                    >
                      {game?.Game_Name.replace(/-/g, " ")}
                    </div>
                  );
                })}
              </div>
              {/* <div className="col-sm-3">
                <div className="footer_inn">
                  <ul>
                    <li>
                      <a href="">SUPER BIRD</a>
                    </li>
                    <li>
                      <a href="">TAP TAP SHOT</a>
                    </li>
                    <li>
                      <a href="">WORD SEARCH</a>
                    </li>
                    <li>
                      <a href="">ROLLING CART</a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col-sm-3">
                <div className="footer_inn">
                  <ul>
                    <li>
                      <a href="">FRUIT CHOP</a>
                    </li>
                    <li>
                      <a href="">CONNECT THE BALL</a>
                    </li>
                    <li>
                      <a href="">MATCH IT RIGHT</a>
                    </li>
                    <li>
                      <a href="">WORD CONNECT</a>
                    </li>
                  </ul>
                </div>
              </div> */}
              {/* <div className="col-sm-3">
                <div className="footer_inn">
                  <ul>
                    <li>
                      <a href="">SOLITAIRE KING</a>
                    </li>
                    <li>
                      <a href="">MERGE CITY</a>
                    </li>
                    <li>
                      <a href="">SWING MASTER POKA</a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
            <div className="row mt-5">
              <div className="col-sm-3">
                <div className="footer_inn">
                  <h6>INFO</h6>
                  <ul>
                    <li>
                      <a onClick={scrollToParent}>ABOUT US</a>
                    </li>
                    <li>
                      <Link to="/terms">TERMS OF SERVICES</Link>
                    </li>
                    <li>
                      <Link to="/kyc">KYC</Link>
                    </li>
                    <li>
                      <Link to="/support">SUPPORT INFO</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3">
                <div className="footer_inn">
                  <h6>POLICIES</h6>
                  <ul>
                    <li>
                      <Link to="/privacy">PRIVACY POLICY</Link>
                    </li>
                    <li>
                      <Link to="/responsible">RESPONSIBLE GAMING POLICY</Link>
                    </li>
                    <li>
                      <Link to="/fair">FAIR PLAY POLICY</Link>
                    </li>
                    <li>
                      <Link to="/refund">REFUND AND CANCELLATION</Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-3"></div>
              <div className="col-sm-3">
                <div className="footer_inn">
                  <h6>CERTIFICATION</h6>
                  <ul>
                    <li>
                      <Link to="/skill">SKILL CERTIFICATE</Link>
                    </li>
                    <li>
                      <a href="/legal">LEGAL OPINION</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 text-center">
            {/* <ul class="footer-menu">
            <li><NavLink to="/disclaimer">Disclaimer</NavLink></li>
            <li><NavLink to="/kyc">KYC</NavLink></li>
            <li><NavLink to="/terms">Terms of services</NavLink></li>
            <li><Link to="/privacy">Privacy Policy</Link></li>
            <li><NavLink to="/responsible">Responsible Gaming Policy</NavLink></li>
            <li><Link to="/fair">Fair Play Policy</Link></li>
            <li><Link to="/refund">Refund And Cancellation</Link></li>
            <li><Link to="/skill">SKILL CERTIFICATE</Link></li>
            <li><Link to="/support">Support Info</Link></li>
           
          </ul> */}

            <div class="col-12 text-center mt-5 text-white">
              <span
                style={{
                  width: "100%",
                  textAlign: "center",
                  color: "#fff",
                  display: "inline-block",
                }}
              >
                Powered By
              </span>
              <img style={{ marginRight: 5 }} src={Thinktrek} alt="Thinktrek" />{" "}
              Thinktrek Entertainment Private Limited
            </div>
            <div class="col-12 text-center mt-3">
              &copy; Copyrights 2025 | All Rights Reserved |
              <a href="https://justgame.in/" target="_blank">
                {" "}
                www.justgame.in
              </a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
