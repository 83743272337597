import logo from "./logo.svg";
import "./App.css";
import JustGameWeb from "./components/JustGameWeb";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Privacy from "./components/Privacy";
import Skill from "./components/Skill";
import Legal from "./components/Legal";
import Support from "./components/Support";
import Terms from "./components/Terms";
import Responsible from "./components/Responsible";
import Fair from "./components/Fair";
import Refund from "./components/Refund";
import Disclaimer from "./components/Disclaimer";
import Kyc from "./components/KYC";
import ContentInnerGame from "./components/ContentInnerGame";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<JustGameWeb />} />
        <Route path="privacy" element={<Privacy />} />
        <Route path="support" element={<Support />} />
        <Route path="skill" element={<Skill />} />
        <Route path="legal" element={<Legal />} />
        <Route path="terms" element={<Terms />} />
        <Route path="responsible" element={<Responsible />} />
        <Route path="fair" element={<Fair />} />
        <Route path="refund" element={<Refund />} />
        <Route path="disclaimer" element={<Disclaimer />} />
        <Route path="kyc" element={<Kyc />} />
        <Route path="/:name" element={<ContentInnerGame />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
