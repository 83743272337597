import React, { useEffect } from "react";
import Thinktrek from "../components/assets/images/thinktrek.png";
import JustGameLogo from "../components/assets/images/just-game-logo.png";
import GooglePlay from "../components/assets/images/google-play.png";
import AppStore from "../components/assets/images/app-store.png";
import Skill1 from "../components/assets/images/legal/le1.jpg";
import Skill2 from "../components/assets/images/legal/le2.jpg";
import Skill3 from "../components/assets/images/legal/le3.jpg";
import Skill4 from "../components/assets/images/legal/le4.jpg";
import Skill5 from "../components/assets/images/legal/le5.jpg";
import Skill6 from "../components/assets/images/legal/le6.jpg";
import Skill7 from "../components/assets/images/legal/le7.jpg";
import Skill8 from "../components/assets/images/legal/le8.jpg";
import Skill9 from "../components/assets/images/legal/le9.jpg";
import Skill10 from "../components/assets/images/legal/le10.jpg";
import Skill11 from "../components/assets/images/legal/le11.jpg";
import Skill12 from "../components/assets/images/legal/le12.jpg";
import Skill13 from "../components/assets/images/legal/le13.jpg";
import Skill14 from "../components/assets/images/legal/le14.jpg";
import Skill15 from "../components/assets/images/legal/le15.jpg";
import Skill16 from "../components/assets/images/legal/le16.jpg";
import Skill17 from "../components/assets/images/legal/le17.jpg";
import Skill18 from "../components/assets/images/legal/le18.jpg";
import Skill19 from "../components/assets/images/legal/le19.jpg";
import Skill20 from "../components/assets/images/legal/le20.jpg";
import Skill21 from "../components/assets/images/legal/le21.jpg";
import Skill22 from "../components/assets/images/legal/le22.jpg";
import Skill23 from "../components/assets/images/legal/le23.jpg";
import Skill24 from "../components/assets/images/legal/le24.jpg";
import Skill25 from "../components/assets/images/legal/le25.jpg";
import Skill26 from "../components/assets/images/legal/le26.jpg";
import Skill27 from "../components/assets/images/legal/le27.jpg";






import Chair from "../components/assets/images/chair.png";
import Footer from "./Footer";
import { Link } from "react-router-dom";
import Header from "./Header";
const Legal = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Header />
      <section className="privacy_sec">
        <div class="container">
          <div class="row">
            <div class="col-12 text-white fs-5">
              <h2 class="inner-title text-center">LEGAL OPINION</h2>

             
             

              <div className="certifi">
                <img className="w-100" src={Skill1} alt="" />
              </div>

              <div className="certifi mt-2">
                <img className="w-100" src={Skill2} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill3} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill4} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill5} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill6} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill7} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill8} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill9} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill10} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill11} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill12} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill13} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill14} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill15} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill16} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill17} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill18} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill19} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill20} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill21} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill22} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill23} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill24} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill25} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill26} alt="" />
              </div>
              <div className="certifi mt-2">
                <img className="w-100" src={Skill27} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Legal;
